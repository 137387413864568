import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import "../../animation/Animation.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
interface TruncateTextProps {
  text: string;
  maxChar: number;
}
export default function ServiceCard({ data ,Url}: any) {
  const navigate = useNavigate(); 
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleLearnMoreClick = () => {
    // Upload the image to a server and get its URL or unique identifier
    const imageUrl = "https://example.com/image.jpg"; // Replace this with the actual URL of your image
    
    // Navigate to another route and send data via URL parameter
    navigate(`/${Url}?text=${encodeURIComponent(`${data.description}`)}&image=${encodeURIComponent(data.image)}&title=${encodeURIComponent(data.title)}`);
};


  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Card
      sx={{ maxWidth: 345, mt: 5 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box height={230} overflow="hidden" position="relative">
        <CardMedia
          component="img"
          height="100%"
          image={data.image}
          alt="green iguana"
          className="zoom-in-image"
          sx={{ zIndex: 5 }}
        />
        {/* <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          sx={{
            background: "black",
            opacity: isHovered || screenWidth < 400 ? 0.7 : 0,
            padding: 2,
            zIndex: 7,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "opacity 0.9s",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              border: "2px dashed white",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              endIcon={<ArrowRightAltIcon fontSize="small" />}
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "white",
                textTransform: "capitalize",
              }}
              color="primary"
              onClick={handleLearnMoreClick}
            >
              Learn More
            </Button>
          </Box>
        </Box> */}
      </Box>
      <CardContent>
        <Typography
          gutterBottom
          sx={{ fontSize: "18px", fontWeight: 500, lineHeight: "27px" }}
          component="div"
        >
          {data.title}
        </Typography>
        <TruncateText text={data.description} maxChar={130} />
        <Button
          variant="text"
          className="!text-primary !font-[600] !text-[15px] !capitalize !-ml-2"
          endIcon={<ArrowRightAltIcon />}
          onClick={handleLearnMoreClick}
        >
          Learn More
        </Button>
      </CardContent>
    </Card>
  );
}
// Inside TruncateText component

function TruncateText({ text, maxChar }: TruncateTextProps) {
  // Specify the maximum number of lines you want to show
  const maxLineCount = 4; // Adjust this value as needed

  if (text.length > maxChar) {
    const lineHeight = 24; // Line height in pixels, adjust as needed
    const maxTextHeight = maxLineCount * lineHeight;

    return (
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: `${lineHeight}px`,
          color: "#8c98a4",
          maxHeight: `${maxTextHeight}px`,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: maxLineCount,
          WebkitBoxOrient: "vertical",
        }}
      >
        {text}
      </Typography>
    );
  }

  return (
    <Typography
      sx={{
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#8c98a4",
      }}
    >
      {text}
    </Typography>
  );
}


