import React, { useEffect, useState } from "react";
import profile from "../assets/railcop_cover.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
import axios from "axios";
import {
  Container,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Modal,
  Typography,
  Pagination,
} from "@mui/material";
import { primary } from "../color/color";
import { Text } from "./Home";
import { baseurl } from "../Helper";

const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Tenders",
    path: "",
  },
];

export const Tenders = () => {
  const [data, setdata] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/tenders`);
        console.log("Data fetched successfully:", response.data);
        setdata(response.data.jobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleLinkClick = (row: any) => {
    const filepath = `${baseurl}/getFile?filepath=${encodeURIComponent(
      row.url
    )}`;
    if (row.url.endsWith(".pdf")) {
      window.open(filepath, "_blank");
    } else {
      setCurrentImage(filepath);
      setModalOpen(true);
    }
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = page * rowsPerPage;

  return (
    <>
      <PageHeader path={path} background={profile} />
      <Container sx={{ mt: 5, mb: 5 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ background: primary, color: "white" }}>
                <TableCell
                  sx={{ color: "white", width: "50%", textAlign: "center" }}
                >
                  Tender Advertisements
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "10%", textAlign: "center" }}
                >
                  Start Date
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "10%", textAlign: "center" }}
                >
                  End Date
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "20%", textAlign: "center" }}
                >
                  View / Download
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(startIndex, endIndex).map((row: any, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ width: "50%", pt: 0, pb: 0 }}>
                    {row.title}
                  </TableCell>
                  <TableCell
                    sx={{ width: "10%", textAlign: "center", pt: 0, pb: 0 }}
                  >
                    {row.start}
                  </TableCell>
                  <TableCell
                    sx={{ width: "10%", textAlign: "center", pt: 0, pb: 0 }}
                  >{` ${row.end}`}</TableCell>
                  <TableCell
                    sx={{
                      width: "20%",
                      px: 5,
                      textAlign: "center",
                      pt: 1,
                      pb: 1,
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <div
                        onClick={() => handleLinkClick(row)}
                        className="bg-primary !text-white cursor-pointer rounded-md px-4 py-[6px]"
                      >
                        View / Download
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination component */}
        <div className="flex justify-center">
          <Pagination
            color="primary"
            count={Math.ceil(data.length / rowsPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
            sx={{ mt: 2, justifyContent: "center" }}
          />
        </div>

      </Container>
      <ImageModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        imageUrl={currentImage}
      />
    </>
  );
};

function ImageModal({ open, onClose, imageUrl }: any) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <img
            src={imageUrl}
            alt="Display"
            style={{ maxWidth: "100%", maxHeight: "90vh" }}
          />
        </Typography>
      </Box>
    </Modal>
  );
}
