import React from 'react'
import profile from "../assets/railcop_cover.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Press release",
    path: "",
  },
];
export const Press = () => {
  return (
    <>
      <PageHeader path={path} background={profile} />
    <div>Press</div>
    </>
  )
}
