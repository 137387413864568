import React from "react";
import { PageHeader } from "../components/pageheader/PageHeader";
import profile from "../assets/railcop_cover.jpg";
import { ClientSliderBanner, LargeBanner } from "./Home";
import bgprofile from "../assets/bgprofile.png";
import profile1 from "../assets/profile1.jpeg";
import { Button, Container } from "@mui/material";
import track from "../assets/Untitled-design-10.png";
import bogi from "../assets/Untitled-design-11.png";
import dollar from "../assets/Untitled-design-12.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Profile",
    path: "",
  },
];
export const Profile = () => {
  return (
    <>
      <PageHeader path={path} background={profile} />
      <Container>
        <div
          className="grid grid-cols-1 md:grid-cols-2 py-4 mt-16 gap-4"
          style={{
            backgroundImage: `url(${bgprofile})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="md:col-span-1">
            <img src={profile1} className="w-full rounded-lg" alt="" />
          </div>
          <div className="md:col-span-1 px-4 relative">
            <p className="text-primary">PROFILE</p>
            <p className="text-secondary font-semibold mt-2 text-[30px]">
              A MULTIDIMENSIONAL COMPANY
            </p>
            <p className="my-5">
              Railway Constructions Pakistan Limited (“RAILCOP” for short),
              headquartered at Islamabad and having regional presence across
              Pakistan, engaged in infrastructure sector including Civil
              Engineering, constructions, innovative engineering, Railway
              equipment manufacturing like Track fittings & fastenings, track
              laying and rehabilitations/up gradations, besides real estate
              development, and other multi engineering fields. RAILCOP’s
              business scope covers various avenues of engineering disciplines
              including infrastructure construction, such as railways, highways,
              municipal facilities, housing and buildings, urban rail transits,
              water channels for irrigation, etc besides mechanical, electrical
              and allied engineering fields. Railway Constructions Pakistan
              Limited (RAILCOP) is a subsidiary of the Ministry of Railways and
              incorporated as a Public Limited Company on 9th August 1980 under
              Companies Act 1913 (amended Companies Ordinance, 1984 and
              companies Act, 2017). RAILCOP being a company is registered with
              the Securities & Exchange Commission of Pakistan (SECP) besides,
              having registration with Pakistan Engineering Council (PEC) in
              Category C-A (No Limit Contractor). RAILCOP is also a member of
              the Islamabad Chamber of Commerce and Industries (ICCI) and also
              enlisted with various Government/Semi-Government and autonomous
              Organizations in Pakistan for execution of various infrastructure
              works.
            </p>
            <div className="absolute -bottom-16 border-b-yellow-400 px-8 bg-white border-[1px] shadow-xl">
              <div className="flex gap-4 items-center">
                <p className="text-[60px] font-semibold text-primary">40 <span className="text-base">+</span></p>
                <div className="">
                  <p className="text-base font-semibold text-secondary">Years</p>
                  <p className="text-[40px] font-semibold text-secondary">EXPERIENCE</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <div className="mt-32">
        <LargeBanner />
      </div>
      <Coreservices />
      <ClientSliderBanner />
    </>
  );
};

export const Coreservices = () => {
  return (
    <div className="mt-16 mb-10">
      <div className="text-center">
        <p className="text-primary text-base">SERVICES</p>
        <p className="text-secondary text-4xl md:text-5xl lg:text-6xl">WHAT WE DO</p>
      </div>
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          <div className="mb-8">
            <img src={track} className="w-20 h-20 md:w-24 md:h-24 mx-auto" alt="" />
            <p className="text-lg md:text-xl text-secondary mt-4 font-semibold">Track Workshop</p>
            <p className="text-base md:text-lg text-secondary  mt-2">
              RAILCOP is operating Railway Track Workshop at Raiwind since July
              2001 for manufacturing of a wide range of high-quality track
              fittings and fastenings, such as Fish Plates, T-Bolts, ER Clips,
              Steel Keys, Cotters Liners, Spikes, Nuts & Bolts of different
              kinds, Bearing Plates, Tie Plates, Slide Chairs, etc.
            </p>
            <Button
              variant="text"
              className="!text-primary mt-4"
              endIcon={<ArrowRightAltIcon />}
            >
              Learn More
            </Button>
          </div>
          <div className="mb-8">
            <img src={bogi} className="w-20 h-20 md:w-24 md:h-24 mx-auto" alt="" />
            <p className="text-lg md:text-xl text-secondary font-semibold mt-4">Track Machine Shop</p>
            <p className="text-base md:text-lg text-secondary  mt-2">
              RAILCOP is operating Track Machine Shop, Lahore since July 2001
              for carrying out complete overhauling and major repairs of a
              variety of Track Machines and Cranes like PU Tamping and Aligning
              Machine, Duomatic Track Machine, Gantry Cranes, Crawler Cranes, and
              other various small machines.
            </p>
            <Button
              variant="text"
              className="!text-primary mt-4"
              endIcon={<ArrowRightAltIcon />}
            >
              Learn More
            </Button>
          </div>
          <div className="mb-8">
            <img src={dollar} className="w-20 h-20 md:w-24 md:h-24 mx-auto" alt="" />
            <p className="text-lg md:text-xl text-secondary font-semibold mt-4">Special Economic Zone</p>
            <p className="text-base md:text-lg text-secondary  mt-2">
              To promote local and foreign investment, the government of Pakistan introduced the Special Economic Zone Act. The law provides SEZs to be set up by the Federal or Provincial Governments themselves or in collaboration with the private sector under different modes of public-private partnership or exclusively.
            </p>
            <Button
              variant="text"
              className="!text-primary mt-4"
              endIcon={<ArrowRightAltIcon />}
            >
              Learn More
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};
