import React, { useEffect, useState } from "react";
import profile from "../assets/railcop_cover.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
import axios from "axios";
import {
  Container,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { primary } from "../color/color";
import { baseurl } from "../Helper";

const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Downloads",
    path: "",
  },
];

export const Downloads = () => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/download`);
        console.log("Data fetched successfully:", response.data);
        setdata(response.data.jobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <PageHeader path={path} background={profile} />
      <Container sx={{ mt: 5, mb: 5 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ background: primary, color: "white" }}>
                <TableCell
                  sx={{
                    color: "white",
                    width: "50%",
                    textAlign: "center",
                    borderRight: "2px solid #ffffff",
                  }}
                >
                  Download
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    width: "50%",
                    textAlign: "center",
                    // No right border here to avoid double border at the end
                  }}
                >
                  View/Download
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {data.map((row:any, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      width: "50%",
                      borderRight: "2px solid #707070",  // Add a right border to the first column cells
                    }}
                  >
                    {row.title}
                  </TableCell>
                  <TableCell sx={{ width: "50%" }}>
                    <div style={{ textAlign: "center" }}>
                      <a
                        href={`http://localhost:4000/getFile?filepath=${encodeURIComponent(row.url)}`}
                        className="bg-primary !text-white rounded-md px-4 py-2"
                        target="_blank"
                        rel="noopener noreferrer" 
                      >
                        View Upload
                      </a>
                    </div>
                  </TableCell>
                </TableRow>

              ))} */}
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "18px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  Company Profile
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <a
                      href={`https://railcop.com.pk/wp-content/uploads/2022/07/RAILCOP_Profile_compressed.pdf`}
                      className="bg-primary !text-white rounded-md px-4 py-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Upload
                    </a>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "18px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  Memorandum & Article of Assocition
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <a
                      href={`https://railcop.com.pk/wp-content/uploads/2022/07/RAILCOP_Profile_compressed.pdf`}
                      className="bg-primary !text-white rounded-md px-4 py-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Upload
                    </a>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "18px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  Service Rules & Regulations of RAILCOP Employees (2016)
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <a
                      href={`https://railcop.com.pk/wp-content/uploads/2022/07/RAILCOP_Profile_compressed.pdf`}
                      className="bg-primary !text-white rounded-md px-4 py-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Upload
                    </a>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "18px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  Code of Conduct & Policies
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}></div>
                </TableCell>
              </TableRow>
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "14px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  i) General Code of Conduct
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <a
                      href={`https://railcop.com.pk/wp-content/uploads/2022/07/RAILCOP_Profile_compressed.pdf`}
                      className="bg-primary !text-white rounded-md px-4 py-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Upload
                    </a>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "14px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  ii) Anti-Corruption Policy
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <a
                      href={`https://railcop.com.pk/wp-content/uploads/2023/02/acp.pdf`}
                      className="bg-primary !text-white rounded-md px-4 py-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Upload
                    </a>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "14px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  iii) Internal Audit Policy
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <a
                      href={`https://railcop.com.pk/wp-content/uploads/2023/02/acp.pdf`}
                      className="bg-primary !text-white rounded-md px-4 py-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Upload
                    </a>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "18px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  Financial Statements (Last Three Years)
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}></div>
                </TableCell>
              </TableRow>
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "14px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  i) Financial Statement for the year 2022-2023
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <a
                      href={`https://railcop.com.pk/wp-content/uploads/2024/03/FS-2023C.pdf`}
                      className="bg-primary !text-white rounded-md px-4 py-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Upload
                    </a>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "14px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  ii) Financial Statement for the year 2021-2022
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <a
                      href={`https://railcop.com.pk/wp-content/uploads/2023/04/Annual-Accounts-for-the-year-2021-22.pdf`}
                      className="bg-primary !text-white rounded-md px-4 py-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Upload
                    </a>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "14px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  iii) Financial Statement for the year 2020-2021
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <a
                      href={`https://railcop.com.pk/wp-content/uploads/2023/04/Annual-Accounts-for-the-year-2021-22.pdf`}
                      className="bg-primary !text-white rounded-md px-4 py-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Upload
                    </a>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                // key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "50%",
                    fontSize: "18px",
                    fontWeight: 700,
                    // borderRight: "2px solid #707070", // Add a right border to the first column cells
                  }}
                >
                  Annual Procurement Plan For the Year 2023-2024
                </TableCell>
                <TableCell sx={{ width: "50%" }}>
                  <div style={{ textAlign: "center" }}>
                    <a
                      href={`https://railcop.com.pk/wp-content/uploads/2023/12/app.pdf`}
                      className="bg-primary !text-white rounded-md px-4 py-2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Upload
                    </a>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};
