import { Box, Container } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import headerpicture from '../../assets/islambadheader.jpeg'
import React from "react";
import { Link } from "react-router-dom";
import { primary } from "../../color/color";
export const PageHeader = ({ path, background }: any) => {
  return (
    <div
    className="exo-font"
      style={{
        width: "100%",

        height: "500px",
        position: "relative",
        marginTop:'180px',
        background: `url(${headerpicture}) center/cover no-repeat`,
        //  clipPath: "polygon(0% 0%, 100% 0%, 100% 94.32%, 49.03% 100%, 0% 94.32%)",
      }}
    >
      <Box
        position={"absolute"}
        top={0}
        left={0}
        sx={{
          // background: "rgba(40, 40, 40, 0.6)",
          width: "100%",
          height: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Container  sx={{textAlign:'center'}} maxWidth={'sm'}>
          <h3 style={{ fontSize: "18px", fontWeight: "600", color: "white" }}>
            Services
          </h3>
          <p style={{ fontSize: "14px", fontWeight: "400", color: "#F7F8FA" }}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Beatae
            placeat libero in expedita dolorem fuga at, ut rem error distinctio
            consectetur nisi consequatur animi eligendi maxime enim pariatur
            aperiam eaque!
          </p>
        </Container> */}
      </Box>
      <div className="absolute top-[43%] sm:left-[10%] left-[2%]">
      <p className="text-secondary font-bold exo  text-[54px] uppercase">{path[1].title}</p>
      </div>
      <Box
        position={"absolute"}
        top={"60%"}
        className='!bg-white py-3 shadow-sm left-36 px-4  '
        
        left={{ xs: "2%", sm: "3%", md: "7%", lg: "10%" }}
        display-={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {path.map((item: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <Link
                to={item.path}
                style={{
                  color: index !== path.length - 1 ? "gray" : primary,
                  marginLeft: "10px",
                  textDecoration: "none",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
                className="!uppercase"
              >
                {item.title}
              </Link>

              {index !== path.length - 1 && (
                <ArrowRightIcon
                  sx={{
                    color: index !== path.length - 1 ? "gray" : "white",
                    fontSize: "28px",
                    fontWeight: "700",
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      </Box>
    </div>
  );
};
