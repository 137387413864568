import React from 'react'
import profile from "../assets/railcop_cover.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Webinar",
    path: "",
  },
];
export const Webinar = () => {
  return (
    <>
      <PageHeader path={path} background={profile} />
    <div>Webinar</div>
    </>
  )
}
