import React, { useEffect, useState } from "react";
import { PageHeader } from "../components/pageheader/PageHeader";
import profile from "../assets/railcop_cover.jpg";
import { Heading, Text } from "./Home";
import { blueshade } from "../color/color";
import axios from "axios";
import { baseurl } from "../Helper";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Vission and mission",
    path: "",
  },
];
export const Vission = () => {
  const [mission, setMission] = useState("");
  const [vission, setVission] = useState("");
  useEffect(() => {
    // Fetch mission and vision data from API and update the state
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/vissionandmission`
        );
        setMission(response.data[0].mission);
        setVission(response.data[0].vission);
        console.log(response.data);
        console.log(response.data[0].vission);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <PageHeader path={path} background={profile} />
      <div className="w-[80%] m-auto">
        {/* <Container sx={{ pt: 10, pb: 10 }} maxWidth={"md"}> */}
        <div className="w-[50%] my-5">
          <Heading font="58px" weight={400} lineheight={70} color={blueshade}>
            VISION
          </Heading>
          <Text font="16px" mt={15} lineheight={29} color={blueshade}>
            {vission}
          </Text>
          <Heading
            font="58px"
            weight={400}
            mt={30}
            lineheight={70}
            color={blueshade}
          >
            MISSION
          </Heading>
          <Text font="16px" mt={15} lineheight={29} color={blueshade}>
            {mission}
          </Text>
          {/* </Container> */}
        </div>
      </div>
    </>
  );
};
