import React, { useEffect } from "react";
import profile from "../assets/railcop_cover.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
import { Heading, StatCompnent, Text } from "./Home";
import { Box, Container, Grid } from "@mui/material";
import ceopic from "../assets/ceo.jpeg";
import { blueshade, primary } from "../color/color";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "CEO message",
    path: "",
  },
];
export const CeoMessage = () => {
  useEffect(() => {
    console.log("mounted");

    return () => {
      console.log("unmounted");
    };
  }, []);
  return (
    <>
      <PageHeader path={path} background={profile} />
      <StatCompnent />
      <Container sx={{pt:10,pb:10}}>
        <Grid container spacing={8}>
          <Grid item md={6}>
            <img src={ceopic} width={'100%'} alt="" />
          </Grid>
          <Grid item md={6}>
            <Box>
              <Text font="16px" color={primary}>CEO, RAILCOP</Text>
              <Heading font="40px" weight={400} mt={18} color={blueshade}>SYED NAJAM SAEED</Heading>
              <Text font="16px" color={blueshade} mt={10} weight={700}>Welcome to our website!</Text>
              <Text font="16px" color={blueshade} >
                Railway Constructions Pakistan Limited (RAILCOP) is a subsidiary
                of Ministry of Railways, Pakistan. It was established in 1980
                and since than it has adhered to the corporate spirit of
                “Innovative Engineering” and cultivated many key business
                segments, namely, civil infrastructure construction, track
                design and constructions, manufacturing of rail fastening
                equipment, rail track maintenance and etc. RAILCOP has a proud
                history of delivering what it promises both nationally and
                internationally. After revitalizing its Vision Statement and
                translating it into a slogan “THINK BIG, WE DO” RAILCOP is
                seizing every opportunity of rapid development, and has
                committed itself to be a vital organ in the growth and
                development of national economy and Pakistan Railways in
                particular by virtue of attracting Foreign Direct Investments
                and local investors in the Railway Infrastructure and related
                projects. Standing at a new historical starting point and facing
                new development opportunities, we will bear in mind our mission,
                and aim it to be a first-class company globally in the coming
                years. Hence, we come forward with a new-age corporate identity
                – an entirely new look with a modernized logo that epitomizes
                innovation, advancement and transformation; Combining all of
                these elements and making them work together into a simplified
                sturdy execution thus representing our strength and reliability.
                This marks a significant milestone in our journey, providing an
                opportunity to reflect on everything RAILCOP stands for and
                everything that we aspire to become as we move forward into an
                exciting future. As you can see, it is not just our logo that
                has changed it’s the culture that is already on the journey of
                transformation. Our mission is a refined version of what we have
                always known best about ourselves: we deliver best-in-class
                services and quality at par with global standards.
              </Text>
              <Text font="16px" color={primary} mt={10} weight={700}>Thank you very much!</Text>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
