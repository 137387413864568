import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Slide,
  Stack,
  Grid,
  Divider,
  Container,
  ButtonBase,
} from "@mui/material";
import air from "../assets/air.jpg";
import labour from "../assets/labour.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import fatima from "../assets/fatima.jpg";
import trainpicture from "../assets/MicrosoftTeams-image-49.jpeg";
import railcop from "../assets/railcop.png";
import "../animation/Animation.css";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import { PrimaryButton } from "../components/buttons/PrimaryButton";
import { SecondaryButton } from "../components/buttons/SecondaryButton";
import train from "../assets/train.png";
import building from "../assets/building.png";
import setting from "../assets/settings-gears.png";
import { blueshade, primary, secondary, whiteshade } from "../color/color";
import railwayold from "../assets/railwaystationold.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { GoogleMap } from "../components/map/Map";
import calender from "../assets/calender.png";
import b1 from "../assets/r6.jpeg";
import traingreen from "../assets/traingreen.png";
import railwayengineering from "../assets/MicrosoftTeams-image-38.png";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import "swiper/swiper-bundle.css";
import circleone from "../assets/circle-1.png";
import circletow from "../assets/circle-2.png";
import tracicon from "../assets/track.png";
import Review from "./review/TeamSlider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ProjectsCard } from "./InternationalProjects";
import { baseurl } from "../Helper";
const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showSlide, setShowSlide] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [isTeamLoaded, setisTeamLoaded] = useState(false);
  const [team, setteam] = useState([]);
  const [sliderData, setsliderData] = useState([
    {
      _id: "",
      title: "",
      subtitle: "",
      description: "",
      order: "",
      active: false,
      image: "",
    },
  ]);
  const nextSlide = () => {
    setShowSlide(false); // Hide the current slide
    setTimeout(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderData.length);
      setShowSlide(true); // Show the next slide
    }, 500); // Adjust the delay time as needed for smoother transitions
  };

  const prevSlide = () => {
    setShowSlide(false); // Hide the current slide

    setTimeout(() => {
      setCurrentSlide(
        (prevSlide) => (prevSlide - 1 + sliderData.length) % sliderData.length
      );
      setShowSlide(true); // Show the previous slide
    }, 500); // Adjust the delay time as needed for smoother transitions
  };

  // useEffect(() => {
  //   const interval = setInterval(nextSlide, 8000); // Auto slide change every 5 seconds
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowSlide(false); // Hide the current slide

      setTimeout(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderData.length);
        setShowSlide(true); // Show the next slide
      }, 500); // Adjust the delay time as needed for smoother transitions
    }, 8000); // Auto slide change every 8 seconds

    return () => clearInterval(interval);
  }, [sliderData]); // Include sliderData in the dependency array to handle changes

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/filterd/homepage`
        );
        console.log("Data fetched successfully:", response.data);
        // Handle the retrieved data as needed
        setsliderData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/filterd/team`);
        console.log("Data fetched successfully:", response.data);
        // Handle the retrieved data as needed

        // Update the state with the processed data

        // Handle the retrieved data as needed
        setisTeamLoaded(true);

        // Update the state with the processed data
        setteam(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={10}
        height="100vh"
        width="100%"
        position="relative"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            zIndex: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            position={"absolute"}
            width={"100%"}
            top={"50%"}
            display={"flex"}
            padding={"5px"}
            zIndex={3}
            justifyContent={"space-between"}
          >
            <Box
              border={`1px solid #0f7d7c`}
              onClick={prevSlide}
              borderRadius={"50%"}
              sx={{ cursor: "pointer" }}
            >
              <KeyboardArrowLeftRoundedIcon sx={{ color: "#0f7d7c" }} />
            </Box>
            <Box
              border={`1px solid #0f7d7c`}
              onClick={nextSlide}
              sx={{ cursor: "pointer" }}
              borderRadius={"50%"}
            >
              <KeyboardArrowRightRoundedIcon sx={{ color: "#0f7d7c" }} />
            </Box>
          </Box>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "100%",
              height: "100%",
              pl: { xs: "20px", md: "150px" },
              animation: showSlide ? "slideInFromBottom 1s ease forwards" : "",
            }}
          >
            <Grid item xs={12} md={6}>
              <p
                // sx={{ color: "black", fontSize: "58px", fontWeight: "600" }}
                className="!capitalize text-border-title3 pos-static back-title3-border"
              >
                {sliderData[currentSlide].title}
              </p>
              <Typography
              className="!capitalize"
                sx={{ color: secondary, fontSize: "58px", fontWeight: "600" }}
              >
                {sliderData[currentSlide].subtitle}
              </Typography>
              <Typography sx={{ fontSize: "20px", position: "relative", p: 1 ,color:secondary}}>
                {sliderData[currentSlide].description}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  borderRadius: "20px",
                  boxShadow: "initial",
                  animation: showSlide
                    ? "slideInFromBottom 5s ease forwards"
                    : "",
                }}
              >
                <img
                  width={"100%"}
                  // height={"400px"}
                  style={{ borderRadius: "20px",height:currentSlide===0?'auto':'500px' }}
                  src={sliderData[currentSlide].image}
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mt={5}>
        <ExperienceBaner />
      </Box>
      <Innovative />
      <ClientSliderBanner />
      <div className="relative px-4">
        <h2 className="text-border-title1 pos-static back-title-border">
          Projects
        </h2>
        <div className="absolute top-[50%] left-4">
          <p className="text-primary">PROJECTS</p>
          <p className="text-secondary text-[60px]">THINK BIG - WE DO</p>
        </div>
      </div>
      <InternationalProjects />
      <LargeBanner />
      <StatCompnent />
      {isTeamLoaded ? <Review data={team} /> : <p>Loading....</p>}
      <div className="relative px-4 py-1 text-center bg-primary ">
        <h2 className="text-border-title2 pos-static back-title-border text-secondary" >
          News
        </h2>
        <div className="absolute top-[38%] left-[25%]">
          <p className="text-[60px] text-white">NEWS AND EVERY FEEDS</p>
        </div>
      </div>
    </>
  );
};

export const InternationalProjects = () => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/HomeProject`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const swiperSettings = {
    spaceBetween: 50,
    slidesPerView: 3,
    autoplay: true,
    autoplaySpeed: 100,
    breakpoints: {
      1024: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 1,
      },
      400: {
        slidesPerView: 1,
      },
    },
  };

  return (
    <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex justify-center">
        <Swiper {...swiperSettings} className="w-full">
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <ProjectsCard data={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};


const Innovative = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${trainpicture})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    
  };

  const backgroundImageStyleWorkshop = {
    backgroundImage: `url(${circleone})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const navigate=useNavigate()

  return (
    <div className=" w-full h-[650px]" style={backgroundImageStyle}>
      <Container className="pt-16">
        <p className="text-primary roboto text-base">CORE SERVICES</p>
        <p className="text-[60px] leading-[70px] roboto-slab text-secondary">INNOVATIVE</p>
        <p className="text-[60px] leading-[70px] roboto-slab  text-secondary">ENGINEERING</p>

        <div className="flex gap-4 mt-8">
          <div
            className="w-20 h-20 flex justify-center items-center"
            style={backgroundImageStyleWorkshop}
          >
            <img
              src={train}
              style={{
                filter:
                  "invert(28%) sepia(77%) saturate(1454%) hue-rotate(151deg) brightness(95%) contrast(88%)",
              }}
              className="w-7"
              alt=""
            />
          </div>
          <div className="w-96">
            <p className="text-2xl font-semibold text-secondary">
              TRACK WORKSHOP
            </p>
            <p className="text-base text-secondary mt-2">
              RAILCOP is operating Railway Track Workshop at Raiwind since July
              2001 for manufacturing of vide range of high quality track
              fittings and fastenings
            </p>
          </div>
        </div>
        <div className="flex gap-4 mt-8">
          <div
            className="w-20 h-20 flex justify-center items-center"
            style={backgroundImageStyleWorkshop}
          >
            <img
              src={tracicon}
              style={{
                filter:
                  "invert(28%) sepia(77%) saturate(1454%) hue-rotate(151deg) brightness(95%) contrast(88%)",
              }}
              className="w-7"
              alt=""
            />
          </div>
          <div className="w-96">
            <p className="text-2xl font-semibold text-secondary">
              TRACK MACHINE SHOP
            </p>
            <p className="text-base text-secondary mt-2">
              RAILCOP is operating Track Machine Shop, Lahore since July 2001
              for carrying out complete overhauling and major repairs.
            </p>
          </div>
        </div>
        <Button variant="contained" className="!bg-primary !mt-4" onClick={()=>navigate('/profile')}>
          Read More{" "}
        </Button>
      </Container>
    </div>
  );
};
export const ClientSliderBanner = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/clients`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,  
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="relative mt-10 bg-secondary py-16">
      <div className="absolute px-16 py-4 rounded-b-2xl rounded-t-sm -top-10 left-[40%] bg-primary">
        <p className="text-white text-2xl font-semibold">Our clients</p>
      </div>
      <Container>
        <div className="px-16 clients">
          <Slider {...settings}>
            {data.map((item: any, index) => (
              <div key={index} className="w-24 h-24 rounded-full">
                <img
                  src={item.image}
                  className="object-cover rounded-full w-24 h-24"
                  alt=""
                />
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  );
};
const ExperienceBaner = () => {
  return (
    <div style={{ width: "75%", margin: "auto" }}>
      <Grid container spacing={2}>
        <Grid item md={6} sx={{ textAlign: "right" }}>
          <Box mt={15}>
            <p style={{ fontSize: "16px", color: "#0f7d7c" }}>ABOUT US</p>
            <p style={{ fontSize: "36px", fontWeight: 500, color: "#00235a" }}>
              A MULTIDIMENSIONAL
            </p>
            <p style={{ fontSize: "36px", fontWeight: 500, color: "#00235a" }}>
              COMPANY
            </p>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            display={"flex"}
            sx={{ borderBottom: "2px solid #0f7d7c", position: "relative" }}
          >
            <p
              style={{
                fontSize: "80px",
                fontWeight: 700,
                lineHeight: "80px",
                color: "#0f7d7c",
              }}
            >
              40
            </p>
            <Box>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "19px",
                  color: "#00235a",
                }}
              >
                YEARS OF
              </p>
              <p
                style={{
                  fontSize: "40px",
                  fontWeight: 700,
                  lineHeight: "48px",
                  color: "#00235a",
                }}
              >
                EXPERIENCE
              </p>
            </Box>

            <Box
              position={"absolute"}
              left={0}
              top={90}
              sx={{ width: "2px", height: "150px", background: "#E4E4E4" }}
            ></Box>
          </Box>
          <Box padding={3}>
            <p>
              Railway Constructions Pakistan Limited (“RAILCOP” for short),
              headquartered at Islamabad and having regional appearance across
              Pakistan, engaged in infrastructure sector including Civil
              Engineering, constructions, innovative engineering, Railway
              equipment manufacturing like Track fittings & fastenings, track
              laying and rehabilitations/up gradations, besides real estate
              development, and other multi engineering fields
            </p>
            <Button variant="text" sx={{ color: "#0f7d7c", mt: -1, ml: -1 }}>
              Read More
            </Button>
          </Box>
        </Grid>
      </Grid>

      <div
        className=""
        style={{
          width: "80%",
          margin: "auto",
          borderTop: `10px solid ${primary}`,
          paddingTop: 50,
        }}
      >
        <div>
          <Grid container spacing={3}>
            <ServiceGridItem imageSrc={train} title="RAILWAY ENGINEERING" url={'/railway-engineering'} />
            <ServiceGridItem imageSrc={building} title="CONSTRUCTION"  url={'/construction'}/>
            <ServiceGridItem imageSrc={setting} title="OTHER SERVICES" url={'/other-services'}/>
          </Grid>
        </div>
      </div>
    </div>
  );
};
const ServiceGridItem = ({ imageSrc, title ,url}: any) => {
  const [hover, sethover] = useState(false);
  const navigate=useNavigate()
  return (
    <Grid item md={4}>
      <div
        style={{
          width: "100%",
          padding: "10px",
          borderBottom: hover ? `4px solid ${primary}` : "",
        }}
        onClick={()=>navigate(url)}
        onMouseLeave={() => sethover(false)}
        onMouseEnter={() => sethover(true)}
      >
        <img
          src={imageSrc}
          style={{
            width: "110px",
            filter:
              "invert(28%) sepia(77%) saturate(1454%) hue-rotate(151deg) brightness(95%) contrast(88%)",
          }}
          alt=""
        />
        <p style={{ fontSize: "18px", fontWeight: 700, marginTop: "32px" }}>
          {title}
        </p>
      </div>
    </Grid>
  );
};
export const LargeBanner = () => {
  const navigate=useNavigate()
  return (
    <div
      style={{ marginTop: "30px", overflow: "hidden", marginBottom: "10px" }}
    >
      <Grid container>
        <Grid item md={6}>
          <Box>
            <img
              src={railwayold}
              style={{ width: "100%", height: "600px" }}
              alt=""
            />
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sx={{ background: secondary, padding: 5, color: "white" }}
        >
          <img src={traingreen} alt="" />
          <Box sx={{ mt: 5 }}>
            <p
              style={{
                fontWeight: 600,
                fontSize: "28px",
                marginBottom: "16px",
              }}
            >
              CONSTRUCTION
            </p>
            <p>
              At RAILCOP, New technologies to increase operational efficiency.
              Simplifying management policies improved the lines of
              communication. The new outlook of RAILCOP through new branding,
              slogan and website is representing the vision of a brand new
              RAILCOP itself. Welcome to the change!
            </p>
          </Box>
          <Box
        
            sx={{
              mt: 5,
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid white",
            }}
          onClick={()=>navigate('/construction')}
          >
            <AddIcon sx={{ color: "white" }}/>
          </Box>
        </Grid>
        <Grid
          item
          md={3}
          sx={{ background: primary, padding: 5, color: "white" }}
        >
          <img src={railwayengineering} style={{ marginTop: "40px" }} alt="" />
          <Box sx={{ mt: 5 }}>
            <p
              style={{
                fontWeight: 600,
                fontSize: "28px",
                marginBottom: "16px",
              }}
            >
              RAILWAY ENGINEERING
            </p>
            <p>
              RAILCOP over the years has established itself as a specialized
              Company bridges for construction of High Speed Railway Track. With
              valuable experience gained on its major Saudi Railways projects,
              the Company can carry out construction of high speed Railways.
            </p>
          </Box>
          <Box
            sx={{
              mt: 5,
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid white",
            }}
            onClick={()=>navigate('/railway-engineering')}
          >
            <AddIcon sx={{ color: "white" }} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export const StatCompnent = () => {
  return (
    <Container sx={{ mb: 5 }}>
      <Box sx={{ textAlign: "center", mt: 5 }}>
        <Text color={primary}>ACCOMPLISHMENTS</Text>

        <Heading weight={400} font="60px" mt={15} color={blueshade}>
          BE SUCCESSFUL WITH US
        </Heading>
      </Box>
      <Grid container sx={{ mt: 5, mb: 5 }}>
        <Grid item md={3}>
          <CircularCard label={"SUCCESS RATIO"} number={"98%"} />
        </Grid>
        <Grid item md={3}>
          <CircularCard label={"HAPPY CLIENTS"} number={"97%"} />
        </Grid>

        <Grid item md={3}>
          <CircularCard label={"TIMELY DONE"} number={"95%"} />
        </Grid>

        <Grid item md={3}>
          <CircularCard label={"COMPETITIVE COST"} number={"100%"} />
        </Grid>
      </Grid>
    </Container>
  );
};
const CircularCard = ({ number, label }: any) => {
  return (
    <Box>
      <Box
        sx={{
          width: "150px",
          height: "150px",
          borderRadius: "50%",
          border: `5px solid ${primary}`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Heading font="28px" color={primary}>
          {number}
        </Heading>
      </Box>
      <Heading color={blueshade}>{label}</Heading>
    </Box>
  );
};
export const Footer = () => {
  return (
    <div style={{ background: "#013C4C" }}>
      <Container sx={{ pt: 15 }}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Heading>ABOUT US</Heading>

                <Typography sx={{ color: "white", mt: 5 }}></Typography>
                <Typography sx={{ color: whiteshade }}>
                  Railway Constructions Pakistan Limited (“RAILCOP” for short),
                  headquartered at Islamabad and having regional appearance
                  across Pakistan, engaged in infrastructure sector including
                  Civil Engineering, constructions, innovative engineering,
                  Railway equipment manufacturing like Track fittings &
                  fastenings, track laying and rehabilitations/up gradations,
                  besides real estate development, and other multi engineering
                  fields.
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 2, mt: 2 }}
                >
                  <img src={calender} width={"50px"} height={"50px"} alt="" />
                  <Box>
                    <Heading>OPENING HOURS</Heading>
                    <Box sx={{ mt: 1 }}>
                      <Text>Mon - Fri 9:00 - 5:00,</Text>
                      <Text>Sat-Sun CLOSED</Text>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Heading>OUR SERVICES</Heading>
                <Box
                  sx={{
                    display: "flex",
                    mt: 5,
                    flexDirection: "column",
                    gap: 3,
                  }}
                >
                  <RailLink to={"railway-engineering"}>
                    Railway Engineering
                  </RailLink>
                  <RailLink to={"track-machine-shop"}>
                    Track Machine Shop
                  </RailLink>
                  <RailLink to={"track-work-shop"}>Track Work Shop</RailLink>
                  <RailLink to={"construction"}>Construction</RailLink>
                  <RailLink to={"other-services"}>Other Services</RailLink>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container>
              <Grid item md={6}>
                <Heading>NEWS & UPDATES</Heading>
                <Box sx={{ mt: 5 }}>
                  <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
                    <img src={b1} width={"80px"} height={"80px"} alt="" />
                    <Box>
                      <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                        <img src={calender} width={"20px"} alt="" />
                        <Text font="14px" color="#aabcd8">
                          {" "}
                          MARCH 29, 2022
                        </Text>
                      </Box>
                      <Text font="14px" weight={700} color="white">
                        MARCH 29, 2022 116TH MEETING OF THE BOARDOF RAILCOP
                      </Text>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                    <img src={b1} width={"80px"} height={"80px"} alt="" />
                    <Box>
                      <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                        <img src={calender} width={"20px"} alt="" />
                        <Text font="14px" color="#aabcd8">
                          {" "}
                          MARCH 29, 2022
                        </Text>
                      </Box>
                      <Text font="14px" weight={700} color="white">
                        MARCH 29, 2022 116TH MEETING OF THE BOARDOF RAILCOP
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ mt: 7 }}>
                  <GoogleMap />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{ textAlign: "center", pt: 3, pb: 3, mt: 5, background: "#124655" }}
      >
        <Text>Copyright ©2022 Railcop. All Rights Reserved</Text>
      </Box>
    </div>
  );
};

interface TextProps {
  font?: "14px" | "12px" | "16px" | "18px" | "20px" | "22px" | "24px";
  weight?: 400 | 600 | 700 | 900;
  color?: string;
  lineheight?: Number;
  mt?: Number;
  children: React.ReactNode;
}

export const Text: React.FC<TextProps> = ({
  children,
  font = "14px",
  weight = 400,
  lineheight,
  mt,
  color = "white",
}) => {
  return (
    <p
      style={{
        fontSize: font,
        fontWeight: weight,
        color: color,
        marginTop: `${mt}px`,
        lineHeight: `${lineheight}px`,
      }}
    >
      {children}
    </p>
  );
};

interface HeadingProps {
  font?: "22px" | "28px" | "32px" | "38px" | "40px" | "58px" | "24px" | "60px";
  weight?: 400 | 600 | 700 | 900;
  color?: string;
  lineheight?: Number;
  mt?: Number;
  children: React.ReactNode;
}

export const Heading: React.FC<HeadingProps> = ({
  children,
  font = "24px",
  mt,
  lineheight,
  weight = 700,
  color = "white",
}) => {
  return (
    <p
      style={{
        fontSize: font,
        fontWeight: weight,
        color: color,
        marginTop: `${mt}px`,
        lineHeight: `${lineheight}px`,
      }}
    >
      {children}
    </p>
  );
};

interface RailLinkProps {
  color?: string;
  to: string;

  children: React.ReactNode;
  hoverColor?: string;
}

const RailLink: React.FC<RailLinkProps> = ({
  color = "#aabcd8",
  to,
  children,
  hoverColor = "#123456", 
}) => {
  const [isHovered, setIsHovered] = useState(false); 

  const linkStyle: React.CSSProperties = {
    textDecoration: "none",
    display: "block",
    color: isHovered ? hoverColor : color,
    transition: "color 0.3s ease", // Add a smooth transition for the color change
  };

  return (
    <Link
      to={to}
      style={linkStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
    </Link>
  );
};
export default Home;