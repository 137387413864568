import React, { useState } from "react";
import { primary } from "../../color/color";
import { Navigate, useNavigate } from "react-router-dom";

export const Login = () => {
 const [email, setEmail] = useState("")
 const [password, setPassword] = useState("")
  const navigate=useNavigate()
 const handleLogin=()=>{
  if(password==='adminrailcop.11' && email==='adminrailcop@gmail.com'){
    navigate('/admin/dashboard')
  } 
 }
  return (
    <div className="w-full flex justify-center items-center h-[100vh]">
      <div className="w-[400px]  flex flex-col gap-6 p-8 border-gray-300 shadow-md rounded-md border-[1px]">
        <p className="text-2xl font-semibold text-primary">Login</p>
        <input type="email" onChange={(e)=>setEmail(e.target.value)} placeholder="email" className="border-gray-400 border-[1px] rounded-lg px-4 py-2" />
        <input type="password" onChange={(e)=>setPassword(e.target.value)} placeholder="password" className="border-gray-400 border-[1px] rounded-lg px-4 py-2" />
        <button className="text-white rounded-full py-2" style={{background:primary}}
        onClick={handleLogin}
        >Login</button>
      </div>
    </div>
  );
};


