import React, { useState } from "react";
import menu from "../../assets/menu.png";
import { AdminHome } from "./pages/AdminHome";
import { AdminServices } from "./pages/AdminServices";
import { AboutUs } from "./pages/AboutUS";
import { AdminProjects } from "./pages/AminProjects";
import { MediaAndNews } from "./pages/MediaAndNews";
import { AdminAdvertisements } from "./pages/AdminAdvertisemet";
import { ContactUsTab } from "./pages/TeamSlider";

export const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("home");

  const renderTabContent = () => {
    switch (activeTab) {
      case 'home':
        return <AdminHome />;
      case 'aboutUs':
        return <AboutUs />;
      case 'services':
        return <AdminServices />;
      case 'projects':
        return <AdminProjects />;
      case 'mediaAndNews':
        return <MediaAndNews />;
      case 'advertisement':
        return <AdminAdvertisements />;
      case 'Team slider':
        return <ContactUsTab />;
      default:
        return null;
    }
  };

  return (
    <div className="">
      <div className="grid grid-cols-12">
        <div className="col-span-2 h-[500px] px-2 border-gray-200 border-[1px] ">
          <div className="w-full h-20 flex items-center justify-center">
            <p className="font-semibold text-2xl text-primary">Railcop</p>
          </div>
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
        <div className="col-span-10">
          <div className="flex items-center gap-4 w-full border-gray-200 px-4 py-2 border-[1px]">
            <img src={menu} className="w-4 h-4" alt="" />
            <p className="text-lg font-semibold capitalize text-primary">
              {activeTab}
            </p>
          </div>
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};



const Tabs = ({ activeTab, setActiveTab }: any) => {
  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  const tabs = [
    { id: 'home', label: 'Home' },
    { id: 'aboutUs', label: 'About Us' },
    { id: 'services', label: 'Services' },
    { id: 'projects', label: 'Projects' },
    { id: 'mediaAndNews', label: 'Media and News' },
    { id: 'advertisement', label: 'Advertisement' },
    { id: 'Team slider', label: 'Team slider' },
  ];

  return (
    <div className="flex flex-col gap-5 pb-20">
      {tabs.map((tab) => (
        <div
          key={tab.id}
          onClick={() => handleTabClick(tab.id)}
          className={`cursor-pointer rounded-md py-2 text-center ${
            activeTab === tab.id ? 'bg-primary text-white' : ''
          }`}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};


