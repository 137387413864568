import React, { useEffect, useState } from "react";
import profile from "../assets/railcop_cover.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
import axios from "axios";
import { Container } from "@mui/material";
import { baseurl } from "../Helper";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Our clients",
    path: "",
  },
];
export const OurClients = () => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/clients`);
        console.log(response.data);
        setdata(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <PageHeader path={path} background={profile} />
      <Container>
        <div className="grid grid-cols-12 gap-4 mt-16 mb-16">
          {data &&
            data.map((item: any) => {
              return (
                <div className=" gap-2 col-span-3 flex flex-col justify-center items-center">
                  <div className="">
                    <img
                      className="rounded-full w-[170px] h-[170px]"
                      src={item.image}
                      alt=""
                    />
                  </div>
                  <p className="">{item.name}</p>
                </div>
              );
            })}
        </div>
      </Container>
    </>
  );
};


