import React, { useEffect, useState } from "react";
import profile from "../assets/railcop_cover.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
import { Button, Container, Grid } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import axios from "axios";
import { baseurl } from "../Helper";

const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Jobs",
    path: "",
  },
];

export const Jobs = () => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/jobs`);
        setdata(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <PageHeader path={path} background={profile} />
      <Container className="my-8">
        <Grid container spacing={4}>
          {data &&
            data.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <JobsCard data={item} />
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
};

const JobsCard = ({ data }: any) => {
  return (
    <div className="border-[1px] border-gray-400 rounded-sm shadow-sm">
      <img src={data.image} className="w-full h-[300px]" alt="" />
      <p className="text-2xl mt-4 mx-2 font-semibold">{data.name}</p>
      <div className="flex items-center">
        <Button variant="text" className="!text-primary !capitalize">
          Learn More
        </Button>
        <ArrowRightAltIcon className="!text-primary" />
      </div>
    </div>
  );
};

