import { useLocation } from "react-router-dom";
import { PageHeader } from "../components/pageheader/PageHeader";
import profile from "../assets/railcop_cover.jpg";
import { Container } from "@mui/material";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "",
    path: "",
  },
];
export function Details() {
  const location = useLocation();
  const searchText = new URLSearchParams(location.search).get("text");
  const imageUrl = new URLSearchParams(location.search).get("image");


  return (
    <div>
      <PageHeader path={path} background={profile} />
      <Container className="mb-8 mt-12">
        <div className="grid grid-cols-12">
          <div className="col-span-4 px-6">
            <div className="border-gray-200 py-6 rounded-sm shadow-md border-[1px]">
              <div className="px-16 flex gap-4">
                <div className="w-[3px] h-10 bg-secondary"></div>
                <p className="text-secondary text-[24px] font-semibold">
                  More services
                </p>
              </div>
              <div className="px-16 flex flex-col py-3 gap-4">
                <div className=" border-b-[1px] pb-2 mb-4 border-b-gray-300">
                  <p className="text-[18px] leading-[27px]">Track Workshop</p>
                </div>

                <div className=" border-b-[1px] mb-4 pb-2 border-b-gray-300">
                  <p className="text-[18px] leading-[27px]">Track Machine Shop</p>
                </div>
                <div className=" border-b-[1px] mb-4 pb-2 border-b-gray-300">
                  <p className="text-[18px] leading-[27px]">Special Economic zone</p>
                </div>
                <div className=" border-b-[1px] mb-4 pb-2 border-b-gray-300">
                  <p className="text-[18px] leading-[27px]">Railway Engineering </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-8">
            <p>{searchText}</p>
            <div className="mt-4">
            {imageUrl && <img src={imageUrl} alt="Image" />}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
