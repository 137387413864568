// CustomDrawer.js
import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import RemoveIcon from "@mui/icons-material/Remove";
import { menuItems } from "../../menuItems";
import { Box, Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
function CustomDrawer({ isOpen, onClose ,Menu}: any) {
//there will be menu for services you should have to integrate by yourself.
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Container sx={{ minWidth: "300px" }}>
        {Menu.map((menu: any, index: any) => {
          return <MenuItem items={menu} key={index} />;
        })}
      </Container>
    </Drawer>
  );
}
const MenuItem = ({ items, key }: any) => {
  console.log(items);
  const [show, setshow] = useState(false);
  return (
    <>
      {items.submenu ? (
        <Box mt={"5px"}  sx={{transition:'1s ease-in-out'}}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            maxWidth={"200px"}
          >
            <Link style={{ textDecoration: "none" ,color:"black" }} to={items.url}>
              {items.title}
            </Link>
            <Box
              borderRadius={"full"}
              sx={{ background: "pr" }}
              bgcolor={show ? "#0F7D7C" : "white"}
              border={"1px solid #ebebeb"}
            >
              {show ? (
                <RemoveIcon
                  fontSize="small"
                  sx={{ color: show ? "white" : "black" }}
                  onClick={() => setshow(false)}
                />
              ) : (
                <AddIcon
                  fontSize="small"
                  sx={{ color: show ? "white" : "black" }}
                  onClick={() => setshow(true)}
                />
              )}
            </Box>
          </Box>
          {show && (
            <Box paddingLeft={'30px'}>
              <SubMenu items={items.submenu} />
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Link style={{ textDecoration: "none" ,color:"black" }} to={items.url}>
            {items.title}
          </Link>
        </Box>
      )}
    </>
  );
};
const SubMenu = ({ items }: any) => {
  return (
    <>
      {items.map((items: any) => {
        return (
          <Box mt='5px'>
            <Link style={{ textDecoration: "none" ,color:"black" }} to={items.url}>
              {items.title}
            </Link>
          </Box>
        );
      })}
    </>
  );
};
export default CustomDrawer;


// j j j j j j j j j j j j j j j j j j j j j j j j j j j

