import { Box, Button, Modal, Switch, useStepContext } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseurl } from "../../../Helper";

export const AdminHome = () => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [order, setorder] = useState("");
  const [selectedImage, setselectedImage] = useState("");
  const [data, setdata] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/homepage`);
        console.log("Data fetched successfully:", response.data);
        // Handle the retrieved data as needed
        console.log(response.data);
        setdata(response.data);
        // Update the state with the processed data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleSubtitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubtitle(e.target.value);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value);
  };
  const handleOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setorder(e.target.value);
  };
  const [status, setStatus] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
  };
    
  const convertToBase64 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        setselectedImage(result);
      };

      reader.readAsDataURL(file);
    }
  };
  const AddItem = async () => {
    try {
      const response = await axios.post(`${baseurl}/homepage`,{
        description:description,
        title:title,
        subtitle:subtitle,
        image:selectedImage,
        order:order,
        status:status
      });
      console.log("Data fetched successfully:", response.data);
      // Handle the retrieved data as needed
      closeModal()
      console.log(response.data);
      
      // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  return (
    <div className="px-8 py-8">
      <div className="">
        <Button
          variant="contained"
          onClick={openModal}
          className="!bg-primary !my-4"
        >
          Add item
        </Button>
      </div>
      <CustomTable data={data} />
      
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <p className="text-2xl font-semibold text-primary">
            Add new Slides data
          </p>

          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6">
              <img src={selectedImage} className="h-[300px]" alt="" />
              <input type="file" accept="image/*" onChange={convertToBase64} />
              <div className="flex">
                <Button
                  variant="contained"
                  className="!bg-primary !mt-4"
                  fullWidth
                  onClick={AddItem}
                >
                  Add
                </Button>
              </div>
            </div>
            <div className="col-span-6">
              <input
                type="text"
                value={title}
                placeholder="title"
                onChange={handleTitleChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full"
              />
              <input
                type="text"
                value={subtitle}
                placeholder="subtitle"
                onChange={handleSubtitleChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full mt-5"
              />

              <textarea
                rows={10}
                placeholder="description"
                value={description}
                onChange={handleDescriptionChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full mt-5"
              />
              <div className="flex items-center gap-8">
                <input
                  type="text"
                  placeholder="order (number)"
                  value={order}
                  onChange={handleOrderChange}
                  className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full mt-5"
                />
                <Switch
                className="!mt-4"
                  checked={status}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const CustomTable = ({ data }: any) => {
  const [selectedData, setselectedData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [order, setorder] = useState("");
  const [selectedImage, setselectedImage] = useState("");
const [id, setid] = useState("")
  const handleData = (data: any) => {
    setTitle(data.title);
    setDescription(data.description);
    setSubtitle(data.subtitle);
    setselectedImage(data.image);
    setorder(data.order);
    setid(data.id)
    setStatus(data.status)
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleSubtitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubtitle(e.target.value);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value);
  };
  const handleOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setorder(e.target.value);
  };
  const [status, setStatus] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
  };
  const Update = async () => {
    try {
      const response = await axios.put(`${baseurl}/homepage/${id}`,{
        id:id,
        description:description,
        title:title,
        subtitle:subtitle,
        image:selectedImage,
        order:order,
        status:status
      });
      console.log("Data fetched successfully:", response.data);
      // Handle the retrieved data as needed
      closeModal()
      console.log(response.data);
      
      // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const Delete = async () => {
    try {
      const response = await axios.delete(`${baseurl}/homepage/${id}`);
      console.log("Data fetched successfully:", response.data);
      // Handle the retrieved data as needed
      closeModal()
      console.log(response.data);
      
      // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const convertToBase64 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        setselectedImage(result);
      };

      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="overflow-x-auto">
      <div className="bg-white border border-gray-300 rounded-md overflow-hidden">
        {/* Table Header */}
        <div className="bg-gray-200 flex">
          <div className="py-2 px-4 border-b flex-1">ID</div>
          <div className="py-2 px-4 border-b flex-1">Order</div>
          <div className="py-2 px-4 border-b flex-1">Title</div>
          <div className="py-2 px-4 border-b flex-1">subtitle</div>
          <div className="py-2 px-4 border-b flex-1">Description</div>
        </div>
        {/* Table Body */}
        <div>
          {/* Sample Data Rows */}
          {data &&
            data.map((item: any) => (
              <div
                className="flex hover:bg-slate-200 cursor-pointer"
                key={item.id}
                onClick={() => {
                  setselectedData(item);
                  openModal();
                  handleData(item);
                }}
              >
                <div className="py-2 px-4 border-b flex-1">{item.id}</div>
                <div className="py-2 px-4 border-b flex-1">{item.order}</div>
                <div className="py-2 px-4 border-b flex-1">{item.title}</div>
                <div className="py-2 px-4 border-b flex-1">{item.subtitle}</div>
                <div className="py-2 px-4 border-b flex-1">
                  {item.description}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6">
              <img src={selectedImage} className="h-[300px]" alt="" />
              <input type="file" accept="image/*" onChange={convertToBase64} />
              <div className="flex gap-4">
                <Button
                  variant="contained"
                  className="!bg-primary !mt-4"
                  fullWidth
                  onClick={Update}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  className="!bg-red-500 !mt-4"
                  fullWidth
                  onClick={Delete}
                >
                  Delete
                </Button>
              </div>
            </div>
            <div className="col-span-6">
              <input
                type="text"
                value={title}
                onChange={handleTitleChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full"
              />
              <input
                type="text"
                value={subtitle}
                onChange={handleSubtitleChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full mt-5"
              />

              <textarea
                rows={10}
                value={description}
                onChange={handleDescriptionChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full mt-5"
              />
              <div className="flex">
                <input
                  type="text"
                  value={order}
                  onChange={handleOrderChange}
                  className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full mt-5"
                />
                <Switch
                  checked={status}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
