import React, { useEffect, useState } from "react";
import profile from "../assets/construction.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
import ServiceCard from "../components/cards/ServiceCard";
import { Box, Container, Grid, Typography, Zoom } from "@mui/material";
import "../animation/Animation.css";
import axios from "axios";
import { baseurl } from "../Helper";

const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Construction",
    path: "",
  },
];

export const ConstructionService = () => {
  const [checked, setChecked] = React.useState(false);
  const [constructopn, setconstructopn] = useState([])
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY >= 100) {
      setChecked((prev) => true);
    } 
    // else {
      
    //   setChecked((prev) => false);
    // }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/constructionservices`);
        console.log("Data fetched successfully:", response.data);
        // Handle the retrieved data as needed
        // setSliderData(response.data);
        setconstructopn(response.data)
        console.log(response.data[2].image.length)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <PageHeader path={path} background={profile} />

      <Container sx={{ mt: 5 }}>
        <Box sx={{ animation: "slideInFromBottom 2s ease forwards" }}>
          <Typography fontSize={'28px'} lineHeight={'42px'} sx={{color:'#3c4858',textTransform:'capitalize'}} fontWeight={600}>
            Construction services
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 2 }} color={'text.secondary'} lineHeight={"24px"}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. In a
            nostrum fugit molestias distinctio molestiae, beatae architecto,
            error similique totam expedita assumenda. Dicta accusantium tempore
            quam exercitationem officia sunt atque.
          </Typography>
          {/* <Typography fontSize={'28px'} lineHeight={'42px'} sx={{color:'#3c4858',textTransform:'capitalize'}} fontWeight={600}>
            We Specialize in 
          </Typography> */}
        </Box>
        <Grid container spacing={2}>
          {
          constructopn &&
          constructopn.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Zoom
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: index * 600 } : {})}
              >
                <div>
                  <ServiceCard data={item} Url={'construction-detail'}/>
                </div>
              </Zoom>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};