import React, { useEffect, useState } from "react";
import { PageHeader } from "../components/pageheader/PageHeader";
import profile from "../assets/construction.jpg";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import axios from "axios";
import { Container, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import { baseurl } from "../Helper";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "International Projects",
    path: "",
  },
];
export const InternationalProjects = () => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/internationalproject`
        );
        console.log("Data fetched successfully:", response.data);
 
        console.log(response.data);
        setdata(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <PageHeader path={path} background={profile} />
      <Container>
        <div className="grid grid-cols-12 gap-y-16 gap-16 lg:p-16 md:p-8 sm:p-2">
          {data &&
            data.map((item, index) => {
              return <ProjectsCard key={index} data={item} />;
            })}
        </div>
      </Container>
    </>
  );
};
export const ProjectsCard = ({ data }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<"" | null>(null);

  const openModal = (image: any) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentImage(null);
    setIsModalOpen(false);
  };
  return (
    <div className="relative mt-16  col-span-12 md:col-span-6 lg:col-span-6 rounded-sm shadow-sm">
      <div className="w-full h-[300px] md:h-[400px]">
        <img
          src={data.image}
          onClick={() => openModal(data.image)}
          className="h-full w-full object-cover"
          alt=""
        />
      </div>
      <div className="flex flex-col  opacity-95  gap-2 absolute -bottom-16 right-0 w-[60%] bg-white p-8 pb-16 rounded-md shadow-lg">
        <p className="text-primary">{data.location}</p>
        <p className="text-[24px] text-secondary roboto-slab  font-bold">{data.title}</p>
        <p className="text-sm roboto font-medium">{data.description}</p>
            
        <div className="flex gap-2 ">
          <PersonOutlineIcon className="!text-primary text-sm" />
          <p className="text-sm">{data.organization}</p>
        </div>
        <div className="flex gap-2">
          <AccessTimeIcon className="!text-primary" />
          <p>{data.duration}</p>
        </div>
      </div>
      <Modal open={isModalOpen} onClose={closeModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            width: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {currentImage && (
            <>
              <div className="relative">
                <img
                  src={currentImage}
                  alt=""
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div className="fixed z-50 right-0 -top-10">
                <Close
                  className="!text-white "
                  onClick={closeModal}
                  fontSize="large"
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
