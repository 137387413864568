import React, { useEffect, useState } from "react";
import profile from "../assets/railcop_cover.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
import { Container, Grid, Modal } from "@mui/material";
import axios from "axios";
import { Close } from "@mui/icons-material";
import { baseurl } from "../Helper";

const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Image gallery",
    path: "",
  },
];

export const Gallery = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [currentImage, setCurrentImage] = useState<{
    title: string;
    description: string;
    image: string;
  } | null>(null);

  const openModal = (image: {
    title: string;
    description: string;
    image: string;
  }) => {
    setCurrentImage(image);
    setIsModalOpen(true);
    console.log(image)
  };

  const closeModal = () => {
    setCurrentImage(null);
    setIsModalOpen(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/image_gallery`);
        console.log("Data fetched successfully:", response.data);
        console.log(response.data);
        setdata(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    console.log(data, "data");
  }, [data]);

  return (
    <>
      <PageHeader path={path} background={profile} />
      <Container sx={{ py: 10 }}>
        {data &&
          data.map((item: any, index) => {
            const imageCount = item.details.length;
            let columns = 0;

            if (imageCount === 2) {
              columns = 6;
            } else if (imageCount === 3) {
              columns = 4;
            } else {
              columns = Math.min(12 / Math.ceil(imageCount / 2), 12);
            }

            return (
              <Grid container spacing={4} mt={2} key={index}>
                <Grid item md={6} sm={12} textAlign="center">
                  <h1 className="font-semibold text-2xl text-secondary">
                    {item.title}
                  </h1>
                  <p className="text-base text-secondary">{item.description}</p>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Grid container spacing={1}>
                    {item.details.map((image: any, subIndex: any) => (
                      <Grid item md={columns} key={subIndex}>
                        <img
                          src={image.image}
                          width="100%"
                          height="100%"
                          alt=""
                          style={{ borderRadius: "5px", cursor: "pointer" }}
                          onClick={() => openModal(image)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Container>

      <Modal open={isModalOpen} onClose={closeModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            width: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {currentImage && (
            <>
              <div className="relative">
                <img
                  src={currentImage.image}
                  alt=""
                  style={{ width: "100%", height: "600px" }}
                />
                {/* <button onClick={closeModal}>Close</button> */}
              </div>
              <div className="fixed z-50 right-0 -top-10">
                
                <Close className="!text-white " onClick={closeModal} fontSize="large"/>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
