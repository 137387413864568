import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { head_office_map } from '../../Helper';

export const GoogleMap = ({src=head_office_map}:any) => {
  const [mapLoaded, setMapLoaded] = useState(true);

  const handleMapLoad = () => {
    // Set the mapLoaded state to true when the iframe has loaded.
    setMapLoaded(true);
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '340px' }}>
      {mapLoaded ? (
  <iframe src={src} width="100%" height="100%" loading="lazy" ></iframe>
      ) : (
        <Skeleton variant="rectangular" width="100%" height="340px" />
      )}
    </div>
  );
};
