import React, { useEffect, useState } from "react";
import profile from "../assets/railcop_cover.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
import axios from "axios";
import { Container } from "@mui/material";
import { baseurl } from "../Helper";

const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Videos",
    path: "",
  },
];

export const Videos = () => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/all_videos`);
        console.log(response.data);
        setdata(response.data.jobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <PageHeader path={path} background={profile} />
      <div className="w-[60%] pt-4 m-auto">
        {data &&
          data.map((item: any, index: number) => {
            const filepath = `${baseurl}/getFile?filepath=${encodeURIComponent(
              item.url
            )}`;
            return (
              <div className="mt-4" key={index}>
                <video controls className="w-full max-h-[500px]">
                  <source src={filepath} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <p className="mt-4 text-center text-lg text-[#6D7882] font-semibold">
                  {item.title}
                </p>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Videos;
