import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseurl } from "../../../Helper";

export const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("profile");

  const renderTabContent = () => {
    switch (activeTab) {
      case "profile":
        return <ProfileTab />;
      case "ceoMessage":
        return <CeoMessageTab />;
      case "visionMission":
        return <VisionMissionTab />;
      case "boardOfDirectors":
        return <BoardOfDirectorsTab />;
      default:
        return null;
    }
  };

  return (
    <div className=" mx-auto px-8 py-8">
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {renderTabContent()}
    </div>
  );
};

const ProfileTab = () => {
  const [selectedImage, setselectedImage] = useState("");
  const [selectedImage2, setselectedImage2] = useState("");
  const [details, setdetails] = useState(""); // State for mission text
  const [id, setid] = useState('')
  const handleMissionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setdetails(e.target.value);
  };

  const convertToBase64 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        setselectedImage(result);
      };

      reader.readAsDataURL(file);
    }
  };
  const convertToBase642 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        setselectedImage2(result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleSaveChanges = async () => {

    try {
      // Send updated mission and vision to the API
      await axios.put(`${baseurl}/companyprofile`, {
        image1:selectedImage ,
        image2:selectedImage2,
        id:id,
        description:details   
    });

      // Optionally, you can fetch the data again to update the state
      // const response = await axios.get('your_api_endpoint');
      // setMission(response.data.mission);
      // setVision(response.data.vision);

      console.log("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  useEffect(() => {
    // Fetch mission and vision data from API and update the state
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/companyprofile`
        );
        setselectedImage(response.data[0].image1);
        setselectedImage2(response.data[0].image2);
        setid(response.data[0].id);
        setdetails(response.data[0].description)
        console.log(response.data);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs once on mount

  return (
    <div className="grid grid-cols-12 w-full">
      <div className="col-span-6">
        <div className="flex">
          <img src={selectedImage} className="w-[50%] h-96" alt="" />
          <img src={selectedImage2} className="w-[50%] h-96" alt="" />
        </div>
        <input type="file" accept="image/*" onChange={convertToBase64} />
        <input type="file" accept="image/*" onChange={convertToBase642} />
      </div>
      <div className="col-span-6 ">
        <textarea
          value={details}
          onChange={handleMissionChange}
          className="border-2"
          cols={70}
          rows={20}
        />
      </div>
      <div className="col-span-12 mt-5">
        <Button variant="contained" className="!bg-primary !text-white" fullWidth
        onClick={handleSaveChanges}
        >
          Save changes
        </Button>
      </div>
    </div>
  );
};

const CeoMessageTab = () => {
  const [selectedImage, setselectedImage] = useState("");

  const [details, setdetails] = useState(""); // State for mission text
  const [id, setid] = useState('')
  const [name, setname] = useState('')
  const handleMissionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setdetails(e.target.value);
  };
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setname(e.target.value);
  };
  const convertToBase64 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        setselectedImage(result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSaveChanges = async () => {

    try {
      // Send updated mission and vision to the API
      await axios.put("${baseurl}/ceoprofile", {
        id: id,
        name:name,
        description:details,
        image:selectedImage
        
      });

      // Optionally, you can fetch the data again to update the state
      // const response = await axios.get('your_api_endpoint');
      // setMission(response.data.mission);
      // setVision(response.data.vision);

      console.log("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };
  useEffect(() => {
    // Fetch mission and vision data from API and update the state
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/ceoprofile`
        );
        setname(response.data[0].name);
        setdetails(response.data[0].description);
        setid(response.data[0].id);
       setselectedImage(response.data[0].image)
        console.log(response.data);
       
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); 

  return <div>
      <div className="grid grid-cols-12 w-full gap-4">
      <div className="col-span-6">
        <div className="flex">
          <img src={selectedImage} className="w-[100%] h-[500px]" alt="" />

        </div>
        <input type="file" accept="image/*" onChange={convertToBase64} />
      </div>
      <div className="col-span-6 ">
        <textarea
          value={details}
          onChange={handleMissionChange}
          className="border-2"
          cols={70}
          rows={20}
        />
             <input
                  type="text"
                  value={name}
                  placeholder="name"
                  onChange={handleTitleChange}
                  className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full"
                />
      </div>
      <div className="col-span-12 mt-5">
        <Button variant="contained" className="!bg-primary !text-white" fullWidth
        onClick={handleSaveChanges}
        >
          Save changes
        </Button>
      </div>
    </div>
  </div>;
};

const VisionMissionTab = () => {
  const [mission, setMission] = useState(""); // State for mission text
  const [vision, setVision] = useState(""); // State for vision text
  const [id, setid] = useState("");
  useEffect(() => {
    // Fetch mission and vision data from API and update the state
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/vissionandmission`
        );
        setMission(response.data[0].mission);
        setVision(response.data[0].vission);
        setid(response.data[0].id);
        console.log(response.data);
        console.log(response.data[0].vission);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); 

  const handleMissionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMission(e.target.value);
  };

  const handleVisionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setVision(e.target.value);
  };

  const handleSaveChanges = async () => {
    console.log(mission, "-", vision);
    try {
      // Send updated mission and vision to the API
      await axios.put(`${baseurl}/vissionandmission`, {
        id: id,
        mission: mission,
        vission: vision,
      });

      // Optionally, you can fetch the data again to update the state
      // const response = await axios.get('your_api_endpoint');
      // setMission(response.data.mission);
      // setVision(response.data.vision);

      console.log("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  return (
    <div>
      <p className="text-2xl font-semibold">Mission</p>
      <textarea
        value={mission}
        onChange={handleMissionChange}
        className="border-2 w-full"
       
        rows={10}
      />
      <p className="text-2xl font-semibold">Vision</p>
      <textarea
        value={vision}
        onChange={handleVisionChange}
        className="border-2 w-full"
       
        rows={10}
      />
      <Button
        variant="contained"
        fullWidth
        className="!bg-primary !text-white"
        onClick={handleSaveChanges}
      >
        Save Changes
      </Button>
    </div>
  );
};

const BoardOfDirectorsTab = () => {
  const [selectedImage, setselectedImage] = useState("");

  const convertToBase64 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        setselectedImage(result);
      };

      reader.readAsDataURL(file);
    }
  };
  const AddItem = async () => {
    try {
      const response = await axios.post(
        `${baseurl}/boardofdirectores`,
        {
          image: selectedImage,
        }
      );
      console.log("Data fetched  1:", response.data);
      // Handle the retrieved data as needed

      console.log(response.data);

      // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    LoadImage();
  }, []);
  const LoadImage = async () => {
    try {
      const response = await axios.get(
        `${baseurl}/boardofdirectores`
      );
      console.log("Data fetched successfully12121:", response.data);
      // Handle the retrieved data as needed

      console.log(response.data.image);
      setselectedImage(response.data[0].image);
      // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div>
      <p>Board of directores</p>
      <img className="w-full h-[600px]" src={selectedImage} alt="" />
      <input type="file" accept="image/*" onChange={convertToBase64} />
      <Button
        className="!bg-primary !text-white !mt-3 "
        fullWidth
        onClick={AddItem}
      >
        Add
      </Button>
    </div>
  );
};

const Tabs = ({ activeTab, setActiveTab }: any) => {
  const tabs = [
    { id: "profile", label: "Profile" },
    { id: "ceoMessage", label: "CEO's Message" },
    { id: "visionMission", label: "Vision and Mission" },
    { id: "boardOfDirectors", label: "Board of Directors" },
  ];

  return (
    <div className="flex gap-8 mb-6">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`${
            activeTab === tab.id
              ? "bg-primary text-white"
              : "border-primary text-primary"
          } rounded-md px-4 py-2`}
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};
