import React, { useEffect, useState } from "react";
import { PageHeader } from "../components/pageheader/PageHeader";
import profile from "../assets/railcop_cover.jpg";
import { Heading, Text } from "./Home";
import { blueshade} from "../color/color";
import { Box, Container } from "@mui/material";
import board from '../assets/bod-8-9.jpeg'
import axios from "axios";
import { baseurl } from "../Helper";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Board of directors",
    path: "",
  },
];
export const Directors = () => {
  const [selectedImage, setSelectedImage] = useState('')
  useEffect(() => {
    LoadImage();
  }, []);
  const LoadImage = async () => {
    try {
      const response = await axios.get(
        `${baseurl}/boardofdirectores`
      );
      console.log("Data fetched successfully12121:", response.data);
      // Handle the retrieved data as needed

      console.log(response.data.image);
      setSelectedImage(response.data[0].image);
      // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <PageHeader path={path} background={profile} />
      <Container sx={{pt:5}}>
        <Heading font="40px" lineheight={48} weight={400} color={blueshade}>
          Board of Directors
        </Heading>
        <Text mt={10} font="16px" lineheight={29} color={blueshade}>
          RAILCOP is managed by a Board of Directors. The Chief Executive
          Officer of the Company is assisted at a senior level of officers and
          supporting staff based at Head Office and Regional Offices around the
          country
        </Text>
        <Box sx={{mt:5,mb:5}}>
          <img src={selectedImage} width={'100%'} alt="" />
        </Box>
      </Container>
    </>
  );
};
