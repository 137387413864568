import React, { useState } from 'react';
import { JobWidget } from '../cmn/Jobcmp';
import { TendersTab } from './Tenders';

export const AdminAdvertisements = () => {
  const [activeTab, setActiveTab] = useState('tenders');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'tenders':
        return <TendersTab />;
      case 'jobs':
        return <JobsTab />;
      default:
        return null;
    }
  };

  return (
    <div className="mx-auto px-8 py-8">
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {renderTabContent()}
    </div>
  );
};
// const TendersTab = () => {
//   return <div>Tenders Content</div>;
// };

const JobsTab = () => {
  return <div><JobWidget url={"jobs"}/></div>;
};

const Tabs = ({ activeTab, setActiveTab }: any) => {
  const tabs = [
    { id: 'tenders', label: 'Tenders' },
    { id: 'jobs', label: 'Jobs' },
  ];

  return (
    <div className="flex gap-8 mb-6">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`${
            activeTab === tab.id ? 'bg-primary text-white' : 'border-primary text-primary'
          } rounded-md px-4 py-2`}
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};
