// Import necessary libraries and icons
import React, { Fragment, useState } from "react";
import { Container } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Swiper, SwiperSlide } from "swiper/react";
import "./review.css";

const Review = ({ data }: any) => {
  return (
    <Container className="my-6 min-h-[450px]">
  <p className="my-6 text-left text-4xl uppercase font-semibold text-primary">Railcop family</p>
    
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        navigation={{
          prevEl: ".prev-btn",
          nextEl: ".next-btn",
        }}
      >
        {data.map((item: any) => (
          <SwiperSlide key={item.title}>
            <article
              className=" relative"
              style={{
                backgroundImage: `url('/path/to/background/image.jpg')`,
              }}
            >
              <div className="grid grid-cols-10 gap-3">
                <div className="relative col-span-1  h-28 ">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-24 h-24"
                  />
                  <span className="absolute -bottom-3 rounded-full p-1 bg-primary left-8">
                    <FormatQuoteIcon sx={{ color: "white" }} />
                  </span>
                </div>
                <div className="col-span-8">
                  <p className="text-left text-[18px] leading-[30px] text-secondary">{item.description}</p>
                  <h4 className="text-left text-[36px] leading[36px] font-semibold text-secondary">{item.title}</h4>
                  <p className=" text-primary text-left text-base font-semibold">{item.subtitle}</p>
                </div>
              </div>
            </article>
          </SwiperSlide>
        ))}
        {/* <div className="button-container flex justify-between absolute w-full top-[50%] left-0">
          <button className="prev-btn">
            <KeyboardArrowLeftIcon fontSize="large" className="text-primary" />
          </button>

          <button className="next-btn">
            <KeyboardArrowRightIcon fontSize="large" className="text-primary" />
          </button>
        </div> */}
        {/* <div className=""></div> */}
      </Swiper>
    </Container>
  );
};

export default Review;