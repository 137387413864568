import MenuItems from "./MenuItems";
import "../../App.css";
// import { menuItems } from '../../menuItems';
import { Box, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import CustomDrawer from "./Drawer";
import axios from "axios";
const Navbar = () => {
  const [menuItems, setmenuItems] = useState([
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Who we are",
      url: "",
      submenu: [
        {
          title: "Profile",
          url: "profile",
        },
        {
          title: "CEO message",
          url: "message",
        },
        {
          title: "Vission and mission",
          url: "vission",
        },

        {
          title: "Board of directors",
          url: "directors",
        },
      ],
    },
    {
      title: "What We Do",
      url: "",
      submenu: [
        {
          title: "Railway Engineering",
          url: "railway-engineering",
          submenu: []
        },
        {
          title: "Construction",
          url: "construction",
          submenu: [
            {
              title: "Frontend",
              url: "frontend",
            },
            {
              title: "NodeJS",
              url: "node",
            },
            {
              title: "PHP",
              url: "php",
            },
          ],
        },
        {
          title: "Other Services",
          url: "other-services",
          submenu: []
        },
      ],
    },
    {
      title: "Projects",
      url: "",
      submenu: [
        {
          title: "international projects",
          url: "international-projects",
        },
        {
          title: "Projects completed",
          url: "completed-projects",
        },
        {
          title: "Projects in progress",
          url: "projects-in-progress",
        },
      ],
    },
    {
      title: "Media and news",
      url: "",
      submenu: [
        {
          title: "Press release",
          url: "press-release",
        },
        {
          title: "Image gallery",
          url: "image-gallery",
        },
        {
          title: "downloads",
          url: "downloads",
        },
        {
          title: "Videos",
          url: "videos",
        },
        {
          title: "Certificate/letters",
          url: "certificate",
        },
        {
          title: "Ouer clients",
          url: "our-clients",
        },
        {
          title: "webinar",
          url: "webinar",
        },
      ],
    },
    {
      title: "Advertisements",
      url: "",
      submenu: [
        {
          title: "Jobs",
          url: "jobs",
        },
        {
          title: "Tenders",
          url: "tenders",
        },
      ],
    },
    {
      title: "Contact us",
      url: "/contact",
    },
  ])

  

  useEffect(() => {

    fetchData();
  }, [])
  const fetchData = async () => {
    try {
      const response = await axios.get("http://localhost:4000/otherservices");
      console.log("Data fetched successfully:", response.data);
      const titlesWithUrls = response.data.map((service: any) => ({
        title: service.title,
        url: `/other-service-detail?text=${encodeURIComponent(`${service.description}`)}&image=${encodeURIComponent(service.image)}&title=${encodeURIComponent(service.title)}` // Generate URL based on title
      }));
  
      setmenuItems((prevMenuItems:any) => {
        return [
          ...prevMenuItems.slice(0, 2), // Keep the first two items unchanged
          {
            ...prevMenuItems[2], // Update the "Services" item
            submenu: [
              ...prevMenuItems[2].submenu.slice(0, 2), // Keep the first two submenus unchanged
              {
                ...prevMenuItems[2].submenu[2], // Update the "Other Services" submenu
                submenu: titlesWithUrls
              }
            ]
          },
          ...prevMenuItems.slice(3) // Keep the remaining items unchanged
        ];
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:4000/railwayservices");
        console.log("Data fetched successfully:", response.data);
        const titlesWithUrlsRailways = response.data.map((service: any) => ({
          title: service.title,
          url: `/railway-detail?text=${encodeURIComponent(`${service.description}`)}&image=${encodeURIComponent(service.image)}&title=${encodeURIComponent(service.title)}`
        }));
  
        setmenuItems((prevMenuItems:any) => [
          ...prevMenuItems.slice(0, 2), 
          {
            ...prevMenuItems[2],
            submenu: [
              { ...prevMenuItems[2].submenu[0], submenu: titlesWithUrlsRailways }, 
              ...prevMenuItems[2].submenu.slice(1), 
            ],
          },
          ...prevMenuItems.slice(3) 
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:4000/constructionservices");
        console.log("Data fetched successfully:", response.data);
        const titlesWithUrlsRailways = response.data.map((service: any) => ({
          title: service.title,
          url: `/construction-detail?text=${encodeURIComponent(`${service.description}`)}&image=${encodeURIComponent(service.image)}&title=${encodeURIComponent(service.title)}`
        }));
        let   temo=[
          {
            title: "Home",
            url: "/",
          },
          {
            title: "Who we are",
            url: "",
            submenu: [
              {
                title: "Profile",
                url: "profile",
              },
              {
                title: "CEO'S message",
                url: "message",
              },
              {
                title: "Vission and mission",
                url: "vission",
              },
      
              {
                title: "Board of directors",
                url: "directors",
              },
            ],
          },
          {
            title: "What We Do",
            url: "",
            submenu: [
              {
                title: "Railway Engineering",
                url: "railway-engineering",
                submenu: []
              },
              {
                title: "Construction",
                url: "construction",
                submenu:titlesWithUrlsRailways,
              },
              {
                title: "Other Services",
                url: "other-services",
                submenu: []
              },
            ],
          },
          {
            title: "Projects",
            url: "",
            submenu: [
              {
                title: "international projects",
                url: "international-projects",
              },
              {
                title: "Projects completed",
                url: "completed-projects",
              },
              {
                title: "Projects in progress",
                url: "projects-in-progress",
              },
            ],
          },
          {
            title: "Media and news",
            url: "",
            submenu: [
              {
                title: "Press release",
                url: "press-release",
              },
              {
                title: "Image gallery",
                url: "image-gallery",
              },
              {
                title: "downloads",
                url: "downloads",
              },
              {
                title: "Videos",
                url: "videos",
              },
              {
                title: "Certificate/letters",
                url: "certificate",
              },
              {
                title: "Ouer clients",
                url: "our-clients",
              },
              {
                title: "webinar",
                url: "webinar",
              },
            ],
          },
          {
            title: "Advertisements",
            url: "",
            submenu: [
              {
                title: "Jobs",
                url: "jobs",
              },
              {
                title: "Tenders",
                url: "tenders",
              },
            ],
          },
          {
            title: "Contact us",
            url: "/contact",
          },
        ]
        setmenuItems(temo)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  

  const [drawerOpen, setDrawerOpen] = useState(false);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
 
  useEffect(() => {
    console.log(menuItems,'menus')
  }, [menuItems])
  
  return (
    <nav>
      <Box display={{ xs: "none", sm: "none", lg: "block" }}>
        <ul className="menus">
          {menuItems.map((menu: any, index: any) => {
            const depthLevel = 0;
            return (
              <MenuItems items={menu} key={index} depthLevel={depthLevel} />
            );
          })}
        </ul>
      </Box>
      <Box
        display={{ sm: "block", lg: "none" }}
        position={"absolute"}
        top={30}
        right={5}
      >
        <MenuIcon onClick={openDrawer} />
      </Box>
      <CustomDrawer isOpen={drawerOpen} Menu={menuItems} onClose={closeDrawer} />
    </nav>
  );
};

export default Navbar;


