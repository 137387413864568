import React, { useEffect, useState } from "react";
import profile from "../assets/train.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
import ServiceCard from "../components/cards/ServiceCard";
import { Box, Container, Grid,  Typography, Zoom } from "@mui/material";
import '../animation/Animation.css'
import s1 from "../assets/s1.jpeg";
import s2 from "../assets/s2.jpeg";
import s3 from "../assets/s3.png";
import s4 from "../assets/s4.png";
import s5 from "../assets/s5.png";
import s6 from "../assets/s6.jpeg";
import s7 from "../assets/s7.jpeg";
import s8 from "../assets/s8.jpeg";
import s9 from "../assets/s9.jpeg";
import axios from "axios";
import { baseurl } from "../Helper";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Railway engineering",
    path: "",
  },
];
const services = [
  {
    title: "Railway Engineering Works",
    body: "RAILCOP’s Railway engineering is a multi-faceted engineering discipline dealing with the design, construction and operation of all types of rail transport systems.",
    path: "",
    image: `${s1}`,
  },
  {
    title: "Track Construction & Rehabilitation",
    body: "We offer a full range of railroad construction and rehabilitation services to clients anywhere in Pakistan and abroad.",
    path: "",
    image: `${s2}`,
  },
  {
    title: "Track Design and Maintenance",
    body: "The safety, reliability and quality of the railways are paramount for passengers, employees and the entire rail network.",
    path: "",
    image: `${s7}`,
  },
  {
    title: "Railway Signalling Systems",
    body: "RAILCOP is involved with Ministry of Railways in the planning, design, construction, and maintenance of signaling, interlocking and block systems.",
    path: "",
    image: `${s3}`,
  },
  {
    title: "Mechanical Engineering Works",
    body: "RAILCOP at the very out-set made efforts to modernize the technological capability of Track Workshop in order to manufacture qualitative & high performance track components.",
    path: "",
    image: `${s4}`,
  },
  {
    title: "Production of Track Fittings & Fastenings",
    body: "RAILCOP can supply experienced and qualified engineers, technicians, and experts relating to all fields of the railway for planning and execution of projects.",
    path: "",
    image: `${s5}`,
  },
  {
    title: "Mechanized Production/Supply of Stone Ballast",
    body: "RAILCOP operates as a supplier and installer of ballast, the company provides engineering, installation, supply, stacking, loading.",
    path: "",
    image: `${s6}`,
  },
  {
    title: "Supply of Railway Human Resources",
    body: "We also provide technical training to Railway staff, technicians, and supporting staff at different levels. These training are held with the help of our engineers and technicians for the survey,",
    path: "",
    image: `${s7}`,
  },
  {
    title: "Training in Railway Fields",
    body: "RAILCOP has a vast reservoir of trained technical personnel of Pakistan Railways in the field of Operation, signaling, telecommunication, maintenance of track, etc",
    path: "",
    image: `${s8}`,
  },
  {
    title: "Rehabilitation of Track Machines/Cranes",
    body: "To improve the availability and performance of Track Machines and Cranes over the Different divisions, Pakistan Railways assigned the services of field maintenance and operations",
    path: "",
    image: `${s9}`,
  },
  {
    title: "Manufacturing & supply of Rolling Stock",
    body: "RAILCOP in association with Pakistan Railways Carriage Factory, Islamabad and Mughalpura Workshop, Lahore, can manufacture and supply various types of passenger coaches",
    path: "",
    image: `${s2}`,
  },
];

export const RailwayService = () => {
  const [checked, setChecked] = React.useState(false);
  const [railwayservices, setRailwayservices] = useState([])
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY >= 220) {
      setChecked((prev) => true);
    } else {
      setChecked((prev) => false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/railwayservices`);
        console.log("Data fetched successfully:", response.data);
        // Handle the retrieved data as needed
        // setSliderData(response.data);
        setRailwayservices(response.data)
        console.log(response.data[2].image.length)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <PageHeader path={path} background={profile} />

      <Container sx={{ mt: 5 }}>
        <Box sx={{ animation: "slideInFromBottom 2s ease forwards" }}>
        <Typography fontSize={'28px'} lineHeight={'42px'} sx={{color:'#3c4858',textTransform:'capitalize'}} fontWeight={600}>
            railway services
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 2 }}  color={'text.secondary'}  lineHeight={"24px"}>
            To survey, plan, construct, renew and maintain railway lines, yards,
            stations, colonies, factories, signalling and telecommunication
            works, electrification and water supply etc. To manufacture, buy,
            sell and repair all types of rolling stock, plant, machinery tools
            etc. To undertake engineering projects other than above including
            construction of steel/concrete bridges, flyovers,
            elevated/underground mass transit railway systems
          </Typography>
          {/* <Typography fontSize={'22px'} fontWeight={600}  >
            We Specialize in 
          </Typography> */}
        </Box>
        <Grid container spacing={2}>
          {
          railwayservices &&
          railwayservices.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Zoom
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: index * 600 } : {})}
              >
                <div>
                  <ServiceCard data={item} Url={'railway-detail'} />
                </div>
              </Zoom>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
