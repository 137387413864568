import React from "react";
import profile from "../assets/railcop_cover.jpg";
import { PageHeader } from "../components/pageheader/PageHeader";
import {  Container, Grid } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import { GoogleMap } from "../components/map/Map";
import MapIcon from "@mui/icons-material/Map";
import { lahore_office_map } from "../Helper";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Contact",
    path: "",
  },
];
export const Contact = () => {
  return (
    <>
      <PageHeader path={path} background={profile} />
      <Container className="!mt-12 !py-12">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <div className="">
              <p className="text-primary font-semibold">Contact us</p>
              <p className="text-2xl font-semibold text-secondary">
                Head Office, Islamabad
              </p>
            </div>
            <div className="flex flex-col mt-4">
              <div className="flex gap-3 items-center">
                <MailOutlineIcon className="!text-primary" />
                <div className="">
                  <p className="text-lg font-semibold text-secondary">
                    EMAIL ADDRESS
                  </p>
                  <p>railcop@railcop.com.pk</p>
                </div>
              </div>
              <div className="flex gap-4 mt-4 items-center">
                <PhoneEnabledIcon className="text-primary" />
                <div className="">
                  <p className="text-lg text-secondary font-semibold">
                    PHONE NUMBER
                  </p>
                  <p className="text-secondary">(92-42) 99200140</p>
                  <p className="text-secondary">(92-42) 36300170</p>
                </div>
              </div>
              <div className="flex gap-2 mt-4 items-center">
                <MapIcon className="!text-primary" />
                <div className="">
                  <p className="font-semibold text-lg text-secondary">
                    OFFICE ADDRESS
                  </p>
                  <p className="text-secondary">
                    RAILCOP House, Pakistan Railways Carriage Factory, I.J
                    Principal Road, Sector I-11/1, Islamabad, Pakistan
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <GoogleMap />
          </Grid>
        </Grid>

        <Grid container spacing={4} className="!mt-12">
          <Grid item xs={12} md={6}>
            <div className="">
              <p className="text-primary">Contact us</p>
              <p className="text-2xl font-semibold text-secondary">
                Regional Office, Lahore
              </p>
            </div>
            <div className="flex flex-col mt-4">
              <div className="flex gap-3 ">
                <MailOutlineIcon className="!text-primary" />
                <div className="">
                  <p className="text-lg font-semibold text-secondary">
                    EMAIL ADDRESS
                  </p>
                  <p className="text-secondary font-semibold">PD/Civil Works</p>
                  <p>railcoplahore@railcop.com.pk</p>
                  <p className="text-secondary font-semibold">Track Workshop</p>
                  <p>railcoptws@railcop.com.pk</p>
                  <p className="text-secondary font-semibold">
                    Track Machine Shop
                  </p>
                  <p>railcoptms@railcop.com.pk</p>
                </div>
              </div>
              <div className="flex gap-4 mt-4 items-center">
                <PhoneEnabledIcon className="text-primary" />
                <div className="">
                  <p className="text-lg text-secondary font-semibold">
                    PHONE NUMBER
                  </p>
                  <p className="text-secondary">(92-51) 9278131</p>
                  <p className="text-secondary">(92-51) 9278134</p>
                  <p className="text-secondary">(92-51) 9278271</p>
                </div>
              </div>
              <div className="flex gap-2 mt-4 items-center">
                <MapIcon className="!text-primary" />
                <div className="">
                  <p className="font-semibold text-lg text-secondary">
                    OFFICE ADDRESS
                  </p>
                  <p className="text-secondary">
                    Pakistan Railways Headquarters Office, Lahore
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <GoogleMap src={lahore_office_map} />
          </Grid>
        </Grid>
        {/* <Grid container spacing={4} className="!mt-12">
          <Grid item xs={12} md={6}>
            <div className="">
              <p className="text-primary">Contact us</p>
              <p className="text-2xl font-semibold text-secondary">
                Regional Office, Multan
              </p>
            </div>
            <div className="flex flex-col mt-4">
              <div className="flex gap-3 items-center">
                <MailOutlineIcon className="!text-primary" />
                <div className="">
                  <p className="text-lg font-semibold text-secondary">
                    EMAIL ADDRESS
                  </p>
                  <p className="text-secondary">railcopmultan@railcop.com.pk</p>
                </div>
              </div>
              <div className="flex gap-4 mt-4 items-center">
                <PhoneEnabledIcon className="text-primary" />
                <div className="">
                  <p className="text-lg text-secondary font-semibold">
                    PHONE NUMBER
                  </p>
                  <p className="text-secondary">(92-61) 4377320</p>
                </div>
              </div>
              <div className="flex gap-2 mt-4 items-center">
                <MapIcon className="!text-primary" />
                <div className="">
                  <p className="font-semibold text-lg text-secondary">
                    OFFICE ADDRESS
                  </p>
                  <p className="text-secondary">
                    House No.42 R-XVI, Rasheed Avenue, Near Bahawalpur By-Pass
                    Chowk, Multan
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <GoogleMap />
          </Grid>
        </Grid> */}
        <Grid container spacing={4} className="!mt-12">
          <Grid item xs={12} md={6}>
            <div className="">
              <p className="text-primary">Contact us</p>
              <p className="text-2xl font-semibold text-secondary">
                Regional Office, Sukkur
              </p>
            </div>
            <div className="flex flex-col mt-4">
              <div className="flex gap-3 items-center">
                <MailOutlineIcon className="!text-primary" />
                <div className="">
                  <p className="text-lg font-semibold text-secondary">
                    EMAIL ADDRESS
                  </p>
                  <p className="text-secondary">railcopsukkur@railcop.com.pk</p>
                </div>
              </div>
              <div className="flex gap-4 mt-4 items-center">
                <PhoneEnabledIcon className="text-primary" />
                <div className="">
                  <p className="text-lg text-secondary font-semibold">
                    PHONE NUMBER
                  </p>
                  <p className="text-secondary">(92-71) 9310084</p>
                  <p className="text-secondary">(92-71) 9310043</p>
                </div>
              </div>
              <div className="flex gap-2 mt-4 items-center">
                <MapIcon className="!text-primary" />
                <div className="">
                  <p className="font-semibold text-lg text-secondary">
                    OFFICE ADDRESS
                  </p>
                  <p className="text-secondary">
                    Railway Ground, Near Sindh High Court Bench, Workshop Road,
                    Sukkur
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <GoogleMap />
          </Grid>
        </Grid>
        <Grid container spacing={4} className="!mt-12">
          <Grid item xs={12} md={6}>
            <div className="">
              <p className="text-primary">Contact us</p>
              <p className="text-2xl font-semibold text-secondary">
                Regional Office, Karachi
              </p>
            </div>
            <div className="flex flex-col mt-4">
              <div className="flex gap-3 items-center">
                <MailOutlineIcon className="!text-primary" />
                <div className="">
                  <p className="text-lg font-semibold text-secondary">
                    EMAIL ADDRESS
                  </p>
                  <p className="text-secondary">
                    railcopkarachi@railcop.com.pk
                  </p>
                </div>
              </div>
              <div className="flex gap-4 mt-4 items-center">
                <PhoneEnabledIcon className="text-primary" />
                <div className="">
                  <p className="text-lg text-secondary font-semibold">
                    PHONE NUMBER
                  </p>
                  <p className="text-secondary">(92-71) 9310084</p>
                  <p className="text-secondary">(92-71) 9310043</p>
                </div>
              </div>
              <div className="flex gap-2 mt-4 items-center">
                <MapIcon className="!text-primary" />
                <div className="">
                  <p className="font-semibold text-lg text-secondary">
                    OFFICE ADDRESS
                  </p>
                  <p className="text-secondary">
                    Railway Ground, Near Sindh High Court Bench, Workshop Road,
                    Sukkur
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <GoogleMap />
          </Grid>
        </Grid>
      </Container>
      {/* <ContactUsForm /> */}
    </>
  );
};
const ContactUsForm = () => {
  return (
    <Container>
      <div className="">
        <div className="text-center">
          <p className="text-primary text-base">LET'S TALK</p>
          <p className="text-secondary text-[60px] heading[70px]">
            GET IN TOUCH{" "}
          </p>
        </div>
        <div className="flex gap-8">
          <div className="">
            <p>
              First Name <span className="text-red-700 font-semibold">*</span>
            </p>
            <div className="flex jus "></div>
            <input type="text" className="border-2 border-black" />
          </div>
          <div className="">
            <p>
              Last Name <span className="text-red-700 font-semibold">*</span>
            </p>
            <div className="flex jus "></div>
            <input type="text" className="border-2 border-black" />
          </div>
        </div>
      </div>
    </Container>
  );
};    
