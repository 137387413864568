import React, { useState } from "react";
import image from "../../../assets/air.jpg";
import { Update, Delete } from "@mui/icons-material";
import { Button } from "@mui/material";
import axios from "axios";
import { baseurl } from "../../../Helper";
export const ClientCard = ({ name, addNew,image,id }: any) => {
  const [title, setTitle] = useState(name);
  const [selectedImage, setselectedImage] = useState(image);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const convertToBase64 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        setselectedImage(result);
      };

      reader.readAsDataURL(file);
    }
  };
  const add= async () => {

    try {
      // Send updated mission and vision to the API
      await axios.post("http://localhost:4000/clients", {
      
        name:title,
        description:"",
        image:selectedImage
        
      });

      // Optionally, you can fetch the data again to update the state
      // const response = await axios.get('your_api_endpoint');
      // setMission(response.data.mission);
      // setVision(response.data.vision);

      console.log("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };
  const update= async () => {

    try {
      // Send updated mission and vision to the API
      await axios.put(`${baseurl}/clients/${id}`, {
        id:id,
        name:title,
        description:"",
        image:selectedImage
        
      });

      // Optionally, you can fetch the data again to update the state
      // const response = await axios.get('your_api_endpoint');
      // setMission(response.data.mission);
      // setVision(response.data.vision);

      console.log("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };
  const deleteuser= async () => {

    try {
      // Send updated mission and vision to the API
      await axios.delete(`http://localhost:4000/clients/${id}`);

      // Optionally, you can fetch the data again to update the state
      // const response = await axios.get('your_api_endpoint');
      // setMission(response.data.mission);
      // setVision(response.data.vision);

      console.log("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };
  return (
    <div className="border-gray-300 col-span-4 border-[1px]">
      <img className="w-full h-72" src={selectedImage} alt="" />
      <input type="file" accept="image/*" onChange={convertToBase64} />
      <input
        type="text"
        value={title}
        onChange={handleTitleChange}
        className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full"
      />
      {addNew ? (
        <Button
          variant="contained"
          className="!bg-primary !mt-4"
          fullWidth
            onClick={add}
        >
          Add new
        </Button>
      ) : (
        <div className="flex gap-4">
          <Button
            variant="contained"
            className="!bg-primary !mt-4"
            fullWidth
              onClick={update}
          >
            Update
          </Button>
          <Button
            variant="contained"
            className="!bg-red-500 !mt-4"
            fullWidth
              onClick={deleteuser}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  );
};
