import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import logo from "../../assets/railcop.png";
import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import govlogo from "../../assets/govlogo.jpeg";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { primary } from "../../color/color";
import XIcon from "@mui/icons-material/X";
import Xlogo from "../../assets/twitter.png";
import InstagramIcon from "@mui/icons-material/Instagram";
// import XIcon from "@mui/icons-material";
const Header = () => {
  return (
    <header
      style={{
        background: "white",
        color: "black",
      }}
    >
      <Box
        display={{ xs: "none", sm: "none", lg: "flex" }}
        width={"100%"}
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: primary,
        }}
      >
        <div className="w-64">
          <p className="text-white">railcop@railcop.com.pk</p>
        </div>
        <a href="URL_OF_X" target="_blank">
          <XIcon
            className="hover:text-secondary"
            sx={{ cursor: "pointer", color: "white",mr:2 }}
          />
        </a>
        <a href="https://facebook.com/railcop.pk" target="_blank">
          <FacebookIcon
            className="hover:text-secondary"
            sx={{ cursor: "pointer", color: "white",mr:2 }}
          />
        </a>
        <a href="https://www.linkedin.com/company/railcop/" target="_blank">
          <LinkedInIcon
            className="hover:text-secondary"
            sx={{ cursor: "pointer", color: "white",mr:2 }}
          />
        </a>
        <a href="https://www.instagram.com/railcop.pk/" target="_blank">
          <InstagramIcon
            className="hover:text-secondary"
            sx={{ cursor: "pointer", color: "white",mr:2 }}
          />
        </a>
      </Box>
      <div className="nav-area ">
        <Link to="/" className="logo">
          <img src={logo} width={"150px"} height={"30px"} alt="" />
        </Link>
        <Box
          display={"flex"}
          // sx={{background:'#5FB8B7'}}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Navbar />
        </Box>
        <div className="fixed right-8 top-14 ">
          <img src={govlogo} className="w-20 h-24" alt="" />
        </div>
      </div>
    </header>
  );
};

export default Header;
