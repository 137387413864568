import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PageHeader } from "../components/pageheader/PageHeader";
import profile from "../assets/construction.jpg";
import { Container } from "@mui/material";
import { primary } from "../color/color";
import axios from "axios";
import { baseurl } from "../Helper";
type car = number;

const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Projects in Progress",
    path: "",
  },
];
export const ProgressProjects = () => {
  const [data, setdata] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/projectinprogress`
        );
        console.log("Data fetched successfully:", response.data);
        setdata(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const totalAmount = data.reduce((acc, curr: any) => {
    // Removing commas and converting string to float for accurate summation
    const currentValue = parseFloat(curr.client.replace(/,/g, ""));
    return acc + currentValue;
  }, 0);

  return (
    <>
      <PageHeader path={path} background={profile} />
      <Container sx={{ mt: 10, mb: 5 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ background: primary, color: "white" }}>
                <TableCell sx={{ color: "white" }}>Sr.NO</TableCell>
                <TableCell sx={{ color: "white" }}>Name of Project</TableCell>
                <TableCell sx={{ color: "white", width: 200 }}>
                  Value (Rs. Million)
                </TableCell>
                <TableCell sx={{ color: "white" }}>Client</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any, index: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.client}</TableCell>
                  <TableCell>{row.price}</TableCell>
                </TableRow>
              ))}
              <TableRow
                key={1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row"></TableCell>
                <TableCell className="!text-base !font-semibold text-secondary">
                  Total
                </TableCell>
                <TableCell className="!text-sm !font-semibold text-secondary">
                  {totalAmount.toFixed(2)}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

