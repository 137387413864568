import axios from "axios";
import React, { useEffect, useState } from "react";
import { ClientCard } from "../cmn/ClientCard";
import { Box, Button, Modal, Switch } from "@mui/material";
import { ImageGallery } from "./ImageGallery";
import { baseurl } from "../../../Helper";

export const MediaAndNews = () => {
  const [activeTab, setActiveTab] = useState("pressRelease");

  const renderTabContent = () => {
    switch (activeTab) {
      case "pressRelease":
        return <PressReleaseTab />;
      case "imageGallery":
        return <ImageGalleryTab />;
      case "downloads":
        return <DownloadsTab />;
      case "webinar":
        return <WebinarTab />;
      case "certificate":
        return <CertificateTab />;
      case "ourClients":
        return <OurClientsTab />;
      case "videos":
        return <VideosTab />;
      default:
        return null;
    }
  };

  return (
    <div className=" mx-auto px-8 py-8">
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {renderTabContent()}
    </div>
  );
};

const BoardOfDirectorsTab = () => {
  return <div>Board of Directors Content</div>;
};

const PressReleaseTab = () => {
  return <div>Press Release Content</div>;
};

const ImageGalleryTab = () => {
  return (
    <div>
      <ImageGallery />
    </div>
  );
};
interface PDFFile {
  title: string;
  url: string;
}

const DownloadsTab: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [data, setdata] = useState([]);
  const [title, setTitle] = useState("");
  const [uploadedUrl, setuploadedUrl] = useState("");
  // Handle file selection
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch( `${baseurl}/savefile`, {
        method: "POST",
        body: formData, // FormData will be sent as multipart/form-data
        // Headers may be required depending on your backend setup, like authentication tokens.
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setuploadedUrl(data.filePath);
        alert("File uploaded successfully");
        // Handle success response here
      } else {
        // Handle server errors or invalid responses
        alert("Upload failed");
      }
    } catch (error) {
      // Handle network errors
      console.error("Error:", error);
      alert("Upload failed");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/download`);
        console.log("Data fetched successfully:", response.data);
        // Handle the retrieved data as needed
        console.log(response.data.jobs);
        setdata(response.data.jobs);
        // Update the state with the processed data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const fileUrl = `${baseurl}/getFile?filepath=${encodeURIComponent(
    "assets/cs-result.pdf"
  )}`;
  const handleAdd = async () => {
    try {
      const response = await axios.post(`${baseurl}/download`, {
        title: title,
        url: uploadedUrl,
      });
      console.log("Data fetched successfully:", response.data);
      // Handle the retrieved data as needed
      closeModal();
      console.log(response.data);

      // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div>
      <Button
        variant="contained"
        onClick={openModal}
        className="!bg-primary !my-4"
      >
        Add item
      </Button>
      {/* <input type="file" onChange={handleFileChange} accept="application/pdf" />
    <button onClick={handleUpload}>Upload PDF</button> */}

      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <p className="text-2xl font-semibold text-primary">
            Add new service data
          </p>

          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 flex justify-between">
              <input
                type="text"
                value={title}
                placeholder="title"
                onChange={handleTitleChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full"
              />
              <div className="flex justify-between">
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept="application/pdf"
                />
                <button onClick={handleUpload}>Upload PDF</button>
              </div>
            </div>
          </div>
          <Button onClick={handleAdd} variant="contained">
            Add{" "}
          </Button>
        </Box>
      </Modal>
      <DownloadTable data={data} />
    </div>
  );
};
const DownloadTable = ({ data, url }: any) => {
  const [selectedData, setselectedData] = useState({});
  const [file, setFile] = useState<File | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedUrl, setuploadedUrl] = useState("");
  const [title, setTitle] = useState("");
  console.log(data, "data in download table");
  const [selectedImage, setselectedImage] = useState("");
  const [id, setid] = useState("");

  const handleData = (data: any) => {
    setTitle(data.title);
    setid(data.id);
    setselectedImage(data.url);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const [status, setStatus] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
  };
  const Update = async () => {
    console.log(uploadedUrl);
    try {
      const response = await axios.put(`${baseurl}/download/${id}`, {
        id: id,
        title: title,
        image: uploadedUrl,
      });
      console.log("Data fetched successfully:", response.data);
      // Handle the retrieved data as needed
      closeModal();
      console.log(response.data);

      // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const Delete = async () => {
    try {
      const response = await axios.delete(
        `${baseurl}/download/${id}`
      );
      console.log("Data fetched successfully:", response.data);
      // Handle the retrieved data as needed
      closeModal();
      console.log(response.data);

      // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    console.log(uploadedUrl);
    try {
      const response = await fetch(`${baseurl}/savefile`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.filePath);
        setuploadedUrl(data.filePath);
        alert("File uploaded successfully");
        // Handle success response here
      } else {
        // Handle server errors or invalid responses
        alert("Upload failed");
      }
    } catch (error) {
      // Handle network errors
      console.error("Error:", error);
      alert("Upload failed");
    }
  };

  return (
    <div className="overflow-x-auto">
      <div className="bg-white border border-gray-300 rounded-md overflow-hidden">
        {/* Table Header */}
        <div className="bg-gray-200 flex">
          <div className="py-2 px-4 border-b flex-1 ">ID</div>
          <div className="py-2 px-4 border-b flex-1">Title</div>
          <div className="py-2 px-4 border-b flex-1">File name</div>
        </div>
        {/* Table Body */}
        <div>
          {/* Sample Data Rows */}
          {data &&
            data.map((item: any) => (
              <div
                className="flex hover:bg-slate-200 cursor-pointer"
                key={item.id}
                onClick={() => {
                  setselectedData(item);
                  openModal();
                  handleData(item);
                }}
              >
                <div className="py-2 px-4 border-b flex-1 ">{item.id}</div>

                <div className="py-2 px-4 border-b flex-1">{item.title}</div>
                <div className="py-2 px-4 border-b flex-1">{item.url}</div>
              </div>
            ))}
        </div>
      </div>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6">
              <div className="flex justify-between">
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept="application/pdf"
                />
                <button onClick={handleUpload}>Upload PDF</button>
              </div>
              <a
                href={`${baseurl}/getFile?filepath=${encodeURIComponent(
                  `${selectedImage}`
                )}`}
                className="bg-green-600 text-white rounded-md px-4 py-2"
                target="_blank"
              >
                View Upload
              </a>
              <div className="flex gap-4">
                <Button
                  variant="contained"
                  className="!bg-primary !mt-4"
                  fullWidth
                  onClick={Update}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  className="!bg-red-500 !mt-4"
                  fullWidth
                  onClick={Delete}
                >
                  Delete
                </Button>
              </div>
            </div>
            <div className="col-span-6">
              <input
                type="text"
                value={title}
                onChange={handleTitleChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full"
              />
              <div className="flex">
                <Switch
                  checked={status}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
const WebinarTab = () => {
  return <div>Webinar Content</div>;
};

const CertificateTab = () => {
  return <div>Certificate Content</div>;
};

const OurClientsTab = () => {
  const [data, setdata] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/clients`);
        console.log("Data fetched successfully:", response.data);
        // Handle the retrieved data as needed
        console.log(response.data);
        setdata(response.data);
        // Update the state with the processed data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Button variant="contained" onClick={openModal}>
            Add new Client
          </Button>
        </div>
        {data &&
          data.map((item: any) => {
            return (
              <ClientCard
                name={item.name}
                id={item.id}
                addNew={false}
                image={item.image}
              />
            );
          })}
      </div>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <p className="text-2xl font-semibold text-primary">
            Add new Slides data
          </p>
          <ClientCard name={""} addNew={true} />
        </Box>
      </Modal>
    </div>
  );
};

const VideosTab = () => {
  return (
    <div>
      <VideoUploader />
    </div>
  );
};
const VideoUploader = () => {
  const [file, setFile] = useState<File | null>(null);
  const [data, setdata] = useState([]);
  const [title, setTitle] = useState("");
  const [uploadedUrl, setuploadedUrl] = useState("");
  const [order, setOrder] = useState("");
  const [end, setEnd] = useState("");
  const [status, setStatus] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
  };
  const handleStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrder(e.target.value);
  };
  const handleEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnd(e.target.value);
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${baseurl}/savefile`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setuploadedUrl(data.filePath);
        alert("File uploaded successfully");
      } else {
        alert("Upload failed");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Upload failed");
    }
  };
  const filepath = `${baseurl}/getFile?filepath=${encodeURIComponent(
    "assets/Funny Cute Cat Videos 2023 #shorts #youtubeshorts #ytshorts @boxtoxtv @awwanimals _@Animals.House..mp4"
  )}`;
  console.log(filepath);
  const handleAdd = async () => {
    try {
      const response = await axios.post(`${baseurl}/video`, {
        title: title,
        url: uploadedUrl,
        order: order,
        status: status,
      });
      console.log("Data fetched successfully:", response.data);
      console.log(response.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <input
          type="file"
          onChange={handleFileChange}
          accept="application/pdf"
        />
        <button onClick={handleUpload}>Upload PDF</button>
      </div>
      <Button
        variant="contained"
        onClick={openModal}
        className="!bg-primary !my-4"
      >
        Add item
      </Button>

      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <p className="text-2xl font-semibold text-primary">
            Add new Video 
          </p>  

          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 flex flex-col mb-3 gap-4">
              <input
                type="text"
                value={title}
                placeholder="title"
                onChange={handleTitleChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full"
              />
              <input
                type="text"
                value={order}
                placeholder="order"
                onChange={handleStartChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full"
              />

              <div className="flex justify-between">
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept="application/pdf"
                />
                <button onClick={handleUpload}>Upload Video</button>
              </div>

            </div>
            <Switch
                  checked={status}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
          </div>
          <Button onClick={handleAdd} variant="contained">
            Save
          </Button>
        </Box>
      </Modal>
      <Videos/>
    </>
  );
};
export const Videos = () => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/all_videos`);
        console.log(response.data);
        setdata(response.data.jobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="">
        {data &&
        data.map((item: any, index: number) => {
          const filepath = `${baseurl}/getFile?filepath=${encodeURIComponent(
            item.url
          )}`;
          return (
            <div className="flex">

            <video controls key={index}>
              <source src={filepath} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button variant="contained" color="error">Delete</Button>
            </div>
          );
        })}

      </div>
    </>
  );
};
const Tabs = ({ activeTab, setActiveTab }: any) => {
  const tabs = [
    { id: "pressRelease", label: "Press Release" },
    { id: "imageGallery", label: "Image Gallery" },
    { id: "downloads", label: "Downloads" },
    { id: "webinar", label: "Webinar" },
    { id: "certificate", label: "Certificate" },
    { id: "ourClients", label: "Our Clients" },
    { id: "videos", label: "Videos" },
  ];

  return (
    <div className="flex gap-8 mb-6">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`${
            activeTab === tab.id
              ? "bg-primary text-white"
              : "border-primary text-primary"
          } rounded-md px-4 py-2`}
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

