import { Routes, Route, useNavigate, Link, Outlet } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/header/Layout";
import { Profile } from "./pages/Profile";
import { CeoMessage } from "./pages/CeoMessage";
import { Vission } from "./pages/Vission";
import { Directors } from "./pages/Directors";
import { RailwayService } from "./pages/RailwayService";
import { OtherServices } from "./pages/OtherServices";
import { ConstructionService } from "./pages/ConstructionService";
import { CompletedProjects } from "./pages/CompletedProjects";
import { ProgressProjects } from "./pages/ProgressProjects";
import { InternationalProjects } from "./pages/InternationalProjects";
import { Downloads } from "./pages/Downloads";
import { Press } from "./pages/Press";
import { Gallery } from "./pages/Gallery";
import { Certificat } from "./pages/Certificat";
import { Videos } from "./pages/Videos";
import { Tenders } from "./pages/Tenders";
import { Jobs } from "./pages/Jobs";
import { Webinar } from "./pages/Webinar";
import { OurClients } from "./pages/OurClients";
import { Contact } from "./pages/Contact";
import { Login } from "./pages/admin/Login";
import { useEffect } from "react";
import { AdminDashboard } from "./pages/admin/AdminDasboard";
import { Details } from "./pages/ServiceDetails";
import { ConstructionSinglePage } from "./pages/ConstructionSinglePage";
import { RailwaySinglePage } from "./pages/RailwaySinglePage";
import { OtherSinglePage } from "./pages/OtherSinglePage";


const App = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
  }, [])
  
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="message" element={<CeoMessage />} />
          <Route path="vission" element={<Vission />} />
          <Route path="directors" element={<Directors />} />
          <Route path="railway-engineering" element={<RailwayService />} />
          <Route path="other-services" element={<OtherServices />} />
          <Route path="construction" element={<ConstructionService />} />
          <Route path="completed-projects" element={<CompletedProjects />} />
          <Route path="projects-in-progress" element={<ProgressProjects />} />
          <Route
            path="international-projects"
            element={<InternationalProjects />}
          />
          <Route path="profile" element={<Profile />} />
          <Route path="downloads" element={<Downloads />} />
          <Route path="press-release" element={<Press />} />
          <Route path="image-gallery" element={<Gallery />} />
          <Route path="certificate" element={<Certificat />} />
          <Route path="videos" element={<Videos />} />
          <Route path="tenders" element={<Tenders />} />
          <Route path="jobs" element={<Jobs />} />
          <Route path="webinar" element={<Webinar />} />
          <Route path="our-clients" element={<OurClients />} />
          <Route path="contact" element={<Contact />} />
          <Route path="details" element={<Details/>} />
          <Route path="construction-detail" element={<ConstructionSinglePage/>} />
          <Route path="railway-detail" element={<RailwaySinglePage/>} />
          <Route path="other-service-detail" element={<OtherSinglePage/>} />
          <Route path="*" element={<NotFound/>} />
        </Route>
        <Route path="admin/*" element={<AdminRoutes />} />
      </Routes>
    </>
  );
};
const NotFound=()=>{
  return(
    <div className="h-[500px]">
      <p>Page not found</p>
    </div>
  )
}
const AdminRoutes = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   // Check if the user is authenticated (based on your logic)
  //   const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

  //   // If not authenticated, redirect to the login page
  //   if (!isAuthenticated) {
  //     navigate('/admin/login');
  //   }
  // }, [navigate]);
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="dashboard" element={<AdminDashboard />} />
    </Routes>

  );
};

export default App;
