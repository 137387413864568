import React, { useEffect, useState } from "react";
import { PageHeader } from "../components/pageheader/PageHeader";
import profile from "../assets/construction.jpg";
import axios from "axios";
import { ProjectsCard } from "./InternationalProjects";
import { baseurl } from "../Helper";
const path = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Projects Completed ",
    path: "",
  },
];
export const CompletedProjects = () => {
  const [data, setdata] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/completedprojects`);
        console.log("Data fetched successfully:", response.data);
        // Handle the retrieved data as needed
        console.log(response.data);
        setdata(response.data)
        // Update the state with the processed data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <PageHeader path={path} background={profile} />
      <div className="grid grid-cols-12 gap-4 lg:p-16 md:p-8 sm:p-2 sm:gap-5">
       {
        data&& data.map((item,index)=>{
          return(
            <ProjectsCard key={index} data={item}/>
          )
        })
       }
     </div>
    </>
  );
};
