import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import axios from "axios";
import React, { ChangeEvent, useEffect, useState } from "react";
import { baseurl } from "../../../Helper";

export const ImageGallery = () => {
  const [data, setdata] = useState([]);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [id, setid] = useState("");
  const [status, setStatus] = React.useState(true);
  const [isTitleAdded, setisTitleAdded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handlSubtitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubtitle(e.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
  };
  const [age, setAge] = React.useState("");

  const handleImageNumnberChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  const AddItem = async () => {
    try {
      const response = await axios.post(`${baseurl}/imageheader`, {
        title: title,
        status: status,
        imageNumber: age,
        description: subtitle,
      });
      console.log("Data fetched successfully:", response.data);
      console.log(response.data);
      setid(response.data.result.ImageHeader.id);
      setisTitleAdded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    console.log(id);
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}/image_gallery`);
        console.log("Data fetched successfully:", response.data);
        // Handle the retrieved data as needed
        console.log(response.data);
        setdata(response.data);
        // Update the state with the processed data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Button variant="contained" onClick={openModal}>
        Add picture
      </Button>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {!isTitleAdded ? (
            <>
              <p className="my-3 font-semibold text-xl text-center">
                Add Details
              </p>
              <input
                type="text"
                value={title}
                placeholder="Title"
                onChange={handleTitleChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-3 w-full"
              />

              <input
                type="text"
                value={subtitle}
                placeholder="Subtile"
                onChange={handlSubtitleChange}
                className="border-gray-400 my-2 border-[1px] rounded-lg px-4 py-3 w-full"
              />
              <div className="flex justify-between items-center">
                <div className="w-[130px]">
                  <Switch
                    className="!mt-4"
                    checked={status}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Number of Images
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Number of Images"
                    onChange={handleImageNumnberChange}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Button
                variant="contained"
                sx={{ mt: 5 }}
                fullWidth
                onClick={() => {
                  AddItem();
                }}
              >
                Add
              </Button>
            </>
          ) : (
            <PictureUplaod id={id} totalImages={age} />
          )}
        </Box>
      </Modal>
      <div className="">
        <CustomTable data={data} />
      </div>
    </div>
  );
};

const PictureUplaod = ({ id, totalImages }: any) => {
  const [selectedImage, setselectedImage] = useState("");
  const [counter, setcounter] = useState(1);
  const convertToBase64 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        setselectedImage(result);
      };

      reader.readAsDataURL(file);
    }
  };
  const AddItem = async () => {
    try {
      const response = await axios.post(`${baseurl}/imagedetails`, {
        header_id: id,
        image: selectedImage,
      });
      console.log("Data fetched successfully:", response.data);
      setcounter(counter + 1);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="">
      {counter > totalImages ? (
        ""
      ) : (
        <>
          <p>
            {counter}/{totalImages}
          </p>
          <input type="file" accept="image/*" onChange={convertToBase64} />
          <Button variant="contained" onClick={AddItem}>
            Add picture
          </Button>
        </>
      )}
    </div>
  );
};
const CustomTable = ({ data, url }: any) => {
  const [selectedData, setselectedData] = useState({
    details: {},
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [order, setorder] = useState("");
  const [selectedImage, setselectedImage] = useState("");
  const [id, setid] = useState("");

  const [price, setprice] = useState("");
  const [client, setclient] = useState("");
  const [organization, setorganization] = useState("");
  const handleclientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setclient(e.target.value);
  };
  const handlepriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setprice(e.target.value);
  };
  const handleData = (data: any) => {
    setTitle(data.title);

    setorder(data.order);
    setid(data.id);
    setprice(data.price);
    setclient(data.client);
    setStatus(data.status);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setorder(e.target.value);
  };
  const [status, setStatus] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
  };
  const Update = async () => {
    try {
      const response = await axios.put(`${baseurl}/${url}/${id}`, {
        id: id,
        description: description,
        title: title,
        image: selectedImage,
        order: order,
        price: price,
        client: client,
        organization: organization,
        status: status,
      });
      console.log("Data fetched successfully:", response.data);
      // Handle the retrieved data as needed
      closeModal();
      console.log(response.data);

      // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const Delete = async () => {
    try {
      const response = await axios.delete(`${baseurl}/image_gallery/${id}`);
      console.log("Data fetched successfully:", response.data);
      // Handle the retrieved data as needed
      closeModal();
      console.log(response.data);

      // Update the state with the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const convertToBase64 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        setselectedImage(result);
      };

      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="overflow-x-auto">
      <div className="bg-white border border-gray-300 rounded-md overflow-hidden">
        {/* Table Header */}
        <div className="bg-gray-200 flex">
          <div className="py-2 px-4 border-b flex-1 ">ID</div>
          <div className="py-2 px-4 border-b flex-1">Title</div>
          <div className="py-2 px-4 border-b flex-1">description</div>
          <div className="py-2 px-4 border-b flex-1">Image Number</div>
          <div className="py-2 px-4 border-b flex-1">Status</div>
        </div>
        {/* Table Body */}
        <div>
          {/* Sample Data Rows */}
          {data &&
            data.map((item: any) => (
              <div
                className="flex hover:bg-slate-200 cursor-pointer"
                key={item.id}
                onClick={() => {
                  setselectedData(item);
                  openModal();
                  handleData(item);
                }}
              >
                <div className="py-2 px-4 border-b flex-1 ">{item.id}</div>
                <div className="py-2 px-4 border-b flex-1">{item.title}</div>
                <div className="py-2 px-4 border-b flex-1">
                  {item.description}
                </div>
                <div className="py-2 px-4 border-b flex-1">
                  {item.imageNumber}
                </div>
                <div className="py-2 px-4 border-b flex-1">{item.status}</div>
              </div>
            ))}
        </div>
      </div>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6">
              <ImageChange data={selectedData.details} />
              <div className="flex gap-4">
                <Button
                  variant="contained"
                  className="!bg-primary !mt-4"
                  fullWidth
                  onClick={Update}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  className="!bg-red-500 !mt-4"
                  fullWidth
                  onClick={Delete}
                >
                  Delete
                </Button>
              </div>
            </div>
            <div className="col-span-6">
              <input
                type="text"
                value={title}
                onChange={handleTitleChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full"
              />
              <input
                type="text"
                value={client}
                placeholder="description"
                onChange={handleclientChange}
                className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full"
              />
          

              <div className="flex">
                <input
                  type="text"
                  value={order}
                  onChange={handleOrderChange}
                  className="border-gray-400 border-[1px] rounded-lg px-4 py-2 w-full mt-5"
                />
                <Switch
                  checked={status}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
const ImageChange = ({ data }: any) => {
  return (
    <div className="flex gap-4">
      {data &&
        data.map((item: any) => {
          return <img src={item.image} className="w-24 h-28" alt="" />;
        })}
    </div>
  );
};
